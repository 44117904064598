import { Button, Input, Space, Table } from "antd";
import React, { useState, useRef } from "react";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdminRequests,
  getBookedSessions,
  getCoachConfirmedSlots,
  getCoaches,
  setAlert,
} from "redux-management";
import { DeleteModal } from "./delete-modal/DeleteModal";
import { ConfirmedSlotsWrapper } from "./confirmed-slots-wrapper/ConfirmedSlotsWrapper";
import { format } from "date-fns";
import { Loader } from "components";

const EditCoachConfirmedSlots = () => {
  const [coachId, setCoachId] = useState(null);
  const [deleteSlotDetails, setDeleteSlotDetails] = useState(null);
  const scrollRef = useRef();
  const { allCoachesList, manageCoachesStatus } = useSelector(
    (state) => state.manageCoaches
  );

  const {
    coachConfirmedSlots,
    editCoachConfirmedSlotsStatus,
    fetchingBookedSessionStatus,
    bookedSessions,
  } = useSelector((state) => state.editCoachConfirmedSlots);
  const dispatch = useDispatch();

  const selectCoachHandler = (value) => {
    setCoachId(value);
  };

  const getSlotsPerRequestByDate = (slots) => {
    let slotsByDate = {};
    slots.forEach((slot) => {
      if (slotsByDate[slot.date]) {
        slotsByDate[slot.date] = [
          ...slotsByDate[slot.date],
          {
            ...slot,
          },
        ];
      } else {
        slotsByDate[slot.date] = [
          {
            ...slot,
          },
        ];
      }
    });

    return slotsByDate;
  };

  let activeRequestSlots = [];
  let currentDate = new Date();
  coachConfirmedSlots.forEach((slot) => {
    if (slot.date >= format(currentDate, "yyyy-MM-dd")) {
      activeRequestSlots.push(slot);
    }
  });

  // getting dates from all slots
  let allDates = activeRequestSlots?.reduce((acc, curr) => {
    return [...acc, curr.date];
  }, []);

  // filtering unique dates and sorting in ascending order
  allDates = Array.from(new Set(allDates)).sort();

  const formattedData = getSlotsPerRequestByDate(activeRequestSlots);
  const coachDetails = allCoachesList?.find((coach) => coach.id === coachId);
  const coachName = `${coachDetails?.first_name} ${coachDetails?.middle_name} ${coachDetails?.last_name}`;

  // get all coaches by api call
  useEffect(() => {
    dispatch(getCoaches());
    dispatch(getAdminRequests());
  }, [dispatch]);

  useEffect(() => {
    if (coachId) {
      dispatch(getCoachConfirmedSlots({ coachId, setAlert }));
      dispatch(getBookedSessions(coachId));
    }
  }, [dispatch, coachId]);

  // return array of slot ids for slots booked by learner
  const bookedSessionIds = bookedSessions.map((session) => session.slot.id);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 180,
      ...getColumnSearchProps("name"),
      render: (_, coach) => {
        return (
          <p
            className="cursor-pointer"
            onClick={() => selectCoachHandler(coach.id)}
          >
            {`${coach.first_name} ${coach.middle_name} ${coach.last_name}`}
          </p>
        );
      },
    },
  ];

  // scrolling to create request if it is active
  useEffect(() => {
    if (coachId) {
      window.scrollTo(0, scrollRef.current.offsetTop - 58);
    }
  }, [coachId]);

  return (
    <main className="xl:col-start-2 pt-4 xl:col-end-7 w-[98%] mx-auto">
      {manageCoachesStatus === "pending" ? (
        <Loader />
      ) : manageCoachesStatus === "rejected" ? (
        <h2 className="text-xl text-center">
          There is a glitch, please reload the page.
        </h2>
      ) : (
        <>
          <h2 className="text-2xl text-center font-medium">Edit Coach Slots</h2>
          <h3 className="text-xl">Select coach to edit:</h3>
          <Table
            className="my-4 border-2 max-h-table"
            dataSource={allCoachesList}
            columns={columns}
            pagination={{
              pageSize: 10,
            }}
            scroll={{
              x: 180,
              y: 195,
            }}
          />
          {coachId && (
            <>
              <div ref={scrollRef} className="h-1"></div>
              <section className="mt-6 min-h-[100vh]">
                <div className="flex flex-wrap gap-2 items-center justify-between">
                  <h3 className="text-xl">
                    Slots provided by{" "}
                    <span className="font-semibold">{coachName}</span> :
                  </h3>
                  <Button
                    type="secondary"
                    onClick={() => selectCoachHandler(null)}
                  >
                    Cancel
                  </Button>
                </div>
                {editCoachConfirmedSlotsStatus === "pending" &&
                  fetchingBookedSessionStatus === "pending" && <Loader />}
                {editCoachConfirmedSlotsStatus === "fulfilled" &&
                  fetchingBookedSessionStatus === "fulfilled" && (
                    <ConfirmedSlotsWrapper
                      formattedData={formattedData}
                      bookedSessionIds={bookedSessionIds}
                      allDates={allDates}
                      setDeleteSlotDetails={setDeleteSlotDetails}
                    />
                  )}
                {(editCoachConfirmedSlotsStatus === "rejected" ||
                  fetchingBookedSessionStatus === "rejected") && (
                  <p className="text-center font-semibold m-16">
                    Unable to load slots.Check your internet connection and
                    please try again
                  </p>
                )}
              </section>
            </>
          )}
          {deleteSlotDetails && (
            <DeleteModal
              coachName={coachName}
              deleteSlotDetails={deleteSlotDetails}
              setDeleteSlotDetails={setDeleteSlotDetails}
            />
          )}
        </>
      )}
    </main>
  );
};

export { EditCoachConfirmedSlots };
