import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { AiOutlineClose } from "assets/icons/icons";
import { addCoach, getCoaches, setAlert, updateCoach } from "redux-management";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import {
  duplicateUser,
  removeEmptyPropertiesFromObject,
  underAgeValidate,
  validateEmail,
  validatePassword,
} from "utils";
import { format } from "date-fns";
import axios from "axios";

const initialErrorMsgs = {
  errorMsgForFirstName: "",
  errorMsgForMiddleName: "",
  errorMsgForLastName: "",
  errorMsgForPhoneNumber: "",
  errorMsgForEmail: "",
  errorMsgForPassword: "",
  errorMsgForMeetLink: "",
};

const initialState = {
  first_name: "",
  middle_name: "",
  last_name: "",
  email: "",
  dob: "",
  fee: "500",
  activeSince: format(new Date(), "yyyy-MM-dd"),
  phone: "",
  gender: "Male",
  isActive: true,
  password: "",
  meet_link: "",
};

const AddNewCoach = ({
  isAddCoach,
  isEdit,
  editDetails,
  manageCoachesDispatch,
}) => {
  const [coachDetails, setCoachDetails] = useState({ ...initialState });
  const {
    first_name,
    middle_name,
    last_name,
    email,
    dob,
    fee,
    activeSince,
    password,
    phone,
    gender,
    isActive,
    meet_link,
  } = coachDetails;
  const dispatch = useDispatch();
  const { allCoachesList } = useSelector((state) => state.manageCoaches);
  const formList = [
    {
      label: "First name",
      name: "first_name",
      value: first_name,
    },
    {
      label: "Middle name",
      name: "middle_name",
      value: middle_name,
    },
    {
      label: "Last name",
      name: "last_name",
      value: last_name,
    },
    {
      label: "Email",
      name: "email",
      value: email,
    },
    {
      label: "Password",
      name: "password",
      value: password,
      isUpdate: isEdit,
    },
    {
      label: "Phone number",
      name: "phone",
      value: phone,
    },
    {
      label: "Fee",
      name: "fee",
      value: fee,
    },
    {
      label: "Active since",
      name: "activeSince",
      value: activeSince,
    },
  ];
  const [errorMsg, setErrorMsg] = useState(initialErrorMsgs);
  const {
    errorMsgForFirstName,
    errorMsgForMiddleName,
    errorMsgForLastName,
    errorMsgForPhoneNumber,
    errorMsgForEmail,
    errorMsgForPassword,
  } = errorMsg;

  const [isMeetLinkInputActive, setIsMeetLinkInputActive] = useState(false);
  const [generatingMeetLink, setGeneratingMeetLink] = useState({
    loading: false,
    error: false,
  });
  const meetLinkRef = useRef(null);
  let errorMsgForDob = "";

  // Stop scroll of background page
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  // set existing coach details when isEdit is true
  useEffect(() => {
    if (isEdit) {
      setCoachDetails(editDetails);
      setGeneratingMeetLink({
        loading: false,
        error: false,
      });
    }
  }, [isEdit, editDetails]);

  const setInputFields = (event, type) => {
    if (type === "checkbox") {
      return setCoachDetails((prevCoachDetails) => ({
        ...prevCoachDetails,
        [event.target.name]: event.target.checked,
      }));
    }
    if (event.target.name === "email") {
      return setCoachDetails((prevCoachDetails) => ({
        ...prevCoachDetails,
        [event.target.name]: event.target.value?.trim().toLowerCase(),
      }));
    }
    setCoachDetails((prevCoachDetails) => ({
      ...prevCoachDetails,
      [event.target.name]: event.target.value?.trim(),
    }));
  };

  const handleClose = (initialState) => {
    manageCoachesDispatch({ type: "HANDLE_CLOSE_MODAL" });
    setCoachDetails({ ...initialState });
    setGeneratingMeetLink({
      loading: false,
      error: false,
    });
  };

  const generateMeetLinkAndAddCoach = async (coach, nameFormat) => {
    let meet_link = "";

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/management-token/`
      );
      console.log("management token", response.data.management_token);
      const responseFrom100ms = await axios({
        method: "POST",
        url: `https://api.100ms.live/v2/rooms`,
        headers: {
          Authorization: `Bearer ${response.data.management_token}`,
          "Content-Type": "application/json",
        },
        data: {
          name: coach.email.replace(".", "-").replace("@", ""),
          description: "This is a sample description for the room",
          region: "in",
        },
      });
      if (responseFrom100ms.status === 200) {
        meet_link = `https://coach.meeraq.com/join-session/${responseFrom100ms.data.id}`;
        dispatch(
          addCoach({
            coachDetails: {
              ...coach,
              ...nameFormat,
              meet_link: meet_link,
            },
            getCoaches,
            setAlert,
          })
        );
        manageCoachesDispatch({ type: "HANDLE_CLOSE_MODAL" });
      } else {
        dispatch(
          setAlert({
            message: "Failed to generate meet link for coach",
            isAlert: true,
            status: "failure",
          })
        );
      }
    } catch (err) {
      dispatch(
        setAlert({
          message: "Failed to generate meet link for coach",
          isAlert: true,
          status: "failure",
        })
      );
    }
  };

  const addCoachHandler = async ({
    event,
    dispatch,
    manageCoachesDispatch,
    coachDetails,
    getCoaches,
    addCoach,
    setAlert,
    allCoachesList,
    removeEmptyPropertiesFromObject,
    errorMsgForEmail,
    errorMsgForPhoneNumber,
    errorMsgForFirstName,
    errorMsgForMiddleName,
    errorMsgForLastName,
    errorMsgForPassword,
    errorMsgForDob,
  }) => {
    event.preventDefault();
    let coach = removeEmptyPropertiesFromObject(coachDetails);
    const { phone } = coach;
    const isAlreadyRegistered = duplicateUser(allCoachesList, phone, "phone");
    const isEmailAlreadyExist = allCoachesList.find(
      (coach) => coach.email === email
    );

    if (isEmailAlreadyExist) {
      return dispatch(
        setAlert({
          message: "The user is already registered with same email.",
          isAlert: true,
          status: "failure",
        })
      );
    }
    if (
      !errorMsgForEmail &&
      !errorMsgForPhoneNumber &&
      !errorMsgForLastName &&
      !errorMsgForFirstName &&
      !errorMsgForMiddleName &&
      !errorMsgForDob &&
      !errorMsgForPassword
    ) {
      const nameFormat = {
        first_name: `${coach.first_name}`,
        middle_name: `${
          coach.middle_name?.trim() === undefined
            ? ""
            : coach.middle_name?.trim()
        }`,
        last_name: `${coach.last_name}`,
      };
      if (isAlreadyRegistered) {
        if (
          window.confirm(
            "User with this phone number already exists. Are you sure you want to add same phone number?"
          ) === true
        ) {
          generateMeetLinkAndAddCoach(coach, nameFormat);
        }
      } else {
        // creating coach meet link
        generateMeetLinkAndAddCoach(coach, nameFormat);
      }
    }
  };

  const updateCoachHandler = ({
    event,
    dispatch,
    manageCoachesDispatch,
    coachDetails,
    getCoaches,
    updateCoach,
    removeEmptyPropertiesFromObject,
    errorMsgForEmail,
    errorMsgForPhoneNumber,
    errorMsgForLastName,
    errorMsgForMiddleName,
    errorMsgForFirstName,
    errorMsgForDob,
  }) => {
    let coach = removeEmptyPropertiesFromObject(coachDetails);
    const { phone } = coach;
    const isAlreadyRegistered = duplicateUser(
      allCoachesList.filter(
        (coachItem) => Number(coachItem.id) !== Number(coach.id)
      ),
      phone,
      "phone"
    );
    event.preventDefault();
    if (
      !errorMsgForEmail &&
      !errorMsgForDob &&
      !errorMsgForPhoneNumber &&
      !errorMsgForLastName &&
      !errorMsgForMiddleName &&
      !errorMsgForFirstName
    ) {
      const isEmailAlreadyExist = allCoachesList
        .filter((coachItem) => coachItem.id !== coach.id)
        .find((coach) => coach.email === email);

      if (isEmailAlreadyExist) {
        return dispatch(
          setAlert({
            message: "The user is already registered with same email.",
            isAlert: true,
            status: "failure",
          })
        );
      } else {
        delete coach.id;
        const nameFormat = {
          first_name: `${coach.first_name}`,
          middle_name: `${
            coach.middle_name?.trim() === undefined
              ? ""
              : coach.middle_name?.trim()
          }`,
          last_name: `${coach.last_name}`,
        };
        if (isAlreadyRegistered) {
          if (
            window.confirm(
              "User with this phone number already exists. Are you sure you want to add same phone number?"
            ) === true
          ) {
            dispatch(
              updateCoach({
                coachDetails: {
                  ...coach,
                  ...nameFormat,
                },
                id: coachDetails.id,
                getCoaches,
                setAlert,
              })
            );
            manageCoachesDispatch({ type: "HANDLE_CLOSE_MODAL" });
          }
        } else {
          dispatch(
            updateCoach({
              coachDetails: {
                ...coach,
                ...nameFormat,
              },
              id: coachDetails.id,
              getCoaches,
              setAlert,
            })
          );
          manageCoachesDispatch({ type: "HANDLE_CLOSE_MODAL" });
        }
      }
    }
  };

  // used to create meeting room while editing coach profile
  const createCoachsRoom = async (email) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/management-token/`
      );
      console.log("management token", response.data.management_token);
      const responseFrom100ms = await axios({
        method: "POST",
        url: `https://api.100ms.live/v2/rooms`,
        headers: {
          Authorization: `Bearer ${response.data.management_token}`,
          "Content-Type": "application/json",
        },
        data: {
          name: email.replace(".", "-").replace("@", ""),
          description: "This is a sample description for the room",
          region: "in",
        },
      });
      console.log(responseFrom100ms);
      if (responseFrom100ms.status === 200) {
        setCoachDetails((prev) => ({
          ...prev,
          meet_link: `https://coach.meeraq.com/join-session/${responseFrom100ms.data.id}`,
        }));
        setGeneratingMeetLink({
          loading: false,
          error: false,
        });
      } else {
        setGeneratingMeetLink({
          loading: false,
          error: true,
        });
      }
    } catch (err) {
      setGeneratingMeetLink({
        loading: false,
        error: true,
      });
    }
  };

  const createMeetLink = async ({ email }) => {
    setErrorMsg((prev) => ({ ...prev, errorMsgForMeetLink: "" }));
    setGeneratingMeetLink((prev) => ({ loading: true, error: false }));
    const isEmailAlreadyExist = allCoachesList.find(
      (coach) => coach.email === email
    );
    if (!isEdit) {
      if (isEmailAlreadyExist) {
        setErrorMsg((prev) => ({
          ...prev,
          errorMsgForMeetLink: "Email that you entered already exist.",
        }));
      } else {
        createCoachsRoom(email);
      }
    } else {
      createCoachsRoom(email);
    }
  };

  if (dob !== "" && !underAgeValidate(dob)) {
    errorMsgForDob = "The age must be more than 18 years";
  } else {
    errorMsgForDob = "";
  }

  const handleBlur = (e) => {
    if (e.target.name === "first_name") {
      if (first_name !== "" && !/^[a-zA-Z ]+$/.test(first_name)) {
        setErrorMsg((prev) => ({
          ...prev,
          errorMsgForFirstName:
            "The name should only contain alphabet and space, no numeric and special characters allowed.",
        }));
      } else {
        errorMsgForFirstName &&
          setErrorMsg((prev) => ({
            ...prev,
            errorMsgForFirstName: "",
          }));
      }
    }
    if (e.target.name === "middle_name") {
      if (middle_name !== "" && !/^[a-zA-Z ]+$/.test(middle_name)) {
        setErrorMsg((prev) => ({
          ...prev,
          errorMsgForMiddleName:
            "The name should only contain alphabet and space, no numeric and special characters allowed.",
        }));
      } else {
        errorMsgForMiddleName &&
          setErrorMsg((prev) => ({
            ...prev,
            errorMsgForMiddleName: "",
          }));
      }
    }
    if (e.target.name === "last_name") {
      if (last_name !== "" && !/^[a-zA-Z ]+$/.test(last_name)) {
        setErrorMsg((prev) => ({
          ...prev,
          errorMsgForLastName:
            "The name should only contain alphabet and space, no numeric and special characters allowed.",
        }));
      } else {
        errorMsgForLastName &&
          setErrorMsg((prev) => ({
            ...prev,
            errorMsgForLastName: "",
          }));
      }
    }
    if (e.target.name === "phone") {
      if (phone !== "" && phone.length !== 10) {
        setErrorMsg((prev) => ({
          ...prev,
          errorMsgForPhoneNumber:
            "Please enter only 10 digits for phone number.",
        }));
      } else {
        errorMsgForPhoneNumber &&
          setErrorMsg((prev) => ({
            ...prev,
            errorMsgForPhoneNumber: "",
          }));
      }
    }
    if (e.target.name === "email") {
      if (email !== "" && !validateEmail(email)) {
        setErrorMsg((prev) => ({
          ...prev,
          errorMsgForEmail: "Please enter a valid email Id.",
        }));
      } else {
        errorMsgForEmail &&
          setErrorMsg((prev) => ({
            ...prev,
            errorMsgForEmail: "",
          }));
      }
    }
    if (e.target.name === "password") {
      if (password !== "" && !validatePassword(password)) {
        setErrorMsg((prev) => ({
          ...prev,
          errorMsgForPassword:
            "Your password must be at least 8 characters long, should contain a combination of uppercase and lowercase letter and number and symbol are required.",
        }));
      } else {
        errorMsgForPassword &&
          setErrorMsg((prev) => ({
            ...prev,
            errorMsgForPassword: "",
          }));
      }
    }
    // if (e.target.name === "meet_link") {
    //   if (meet_link !== "" && !validateMeetLink(meet_link)) {
    //     setErrorMsg((prev) => ({
    //       ...prev,
    //       errorMsgForMeetLink: "Your meet link is invalid",
    //     }));
    //   } else {
    //     errorMsgForMeetLink &&
    //       setErrorMsg((prev) => ({
    //         ...prev,
    //         errorMsgForMeetLink: "",
    //       }));
    //   }
    // }
  };

  if (!isAddCoach) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <div className="fixed inset-0 z-modal-bg bg-slate-300 opacity-70" />
      <section className="py-1 px-8 z-modal max-h-modal overflow-scroll w-11/12 sm:w-9/12 lg:w-2/3 xl:w-1/3 border border-zinc-400 rounded fixed top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 bg-white">
        <h2 className="text-2xl my-3 font-medium text-center">
          {isEdit ? "Edit Coach Details" : "Add Coach Details"}
        </h2>
        <AiOutlineClose
          className="text-2xl absolute top-4 right-2 cursor-pointer"
          onClick={() => handleClose(initialState)}
        />
        <form
          className="flex flex-col"
          onSubmit={
            isEdit
              ? (event) =>
                  updateCoachHandler({
                    event,
                    dispatch,
                    manageCoachesDispatch,
                    coachDetails,
                    getCoaches,
                    updateCoach,
                    removeEmptyPropertiesFromObject,
                    errorMsgForEmail,
                    errorMsgForPhoneNumber,
                    errorMsgForLastName,
                    errorMsgForMiddleName,
                    errorMsgForFirstName,
                    errorMsgForDob,
                  })
              : (event) =>
                  addCoachHandler({
                    event,
                    dispatch,
                    manageCoachesDispatch,
                    coachDetails,
                    getCoaches,
                    addCoach,
                    setAlert,
                    allCoachesList,
                    removeEmptyPropertiesFromObject,
                    errorMsgForEmail,
                    errorMsgForPhoneNumber,
                    errorMsgForLastName,
                    errorMsgForMiddleName,
                    errorMsgForFirstName,
                    errorMsgForPassword,
                    errorMsgForDob,
                  })
          }
        >
          {formList.map((item) => {
            if (!item.isUpdate) {
              return (
                <div key={item.label}>
                  <label className="block" htmlFor={item.label}>
                    {item.label}
                    {": "}
                    <span
                      className={`${
                        item.label === "First name" ||
                        item.label === "Last name" ||
                        item.label === "Email" ||
                        item.label === "Password" ||
                        item.label === "Meet link" ||
                        item.label === "Phone number"
                          ? "inline-block text-red-600"
                          : "hidden"
                      }`}
                    >
                      *
                    </span>
                  </label>
                  <input
                    type={
                      item.name === "activeSince"
                        ? "date"
                        : item.name === "phone"
                        ? "number"
                        : item.name === "fee"
                        ? "number"
                        : "text"
                    }
                    step={item.name === "fee" ? "50" : ""}
                    id={item.label}
                    name={item.name}
                    value={item.value}
                    required={
                      item.label === "First name" ||
                      item.label === "Last name" ||
                      item.label === "Email" ||
                      item.label === "Password" ||
                      item.label === "Meet link" ||
                      item.label === "Phone number"
                        ? true
                        : false
                    }
                    placeholder={`Enter ${item.name}`}
                    className=" border w-full border-black outline-0 mb-1 p-1"
                    onChange={(event) => setInputFields(event)}
                    onBlur={handleBlur}
                  />
                  {item.label === "Last name" ? (
                    <p className="text-red-500">{errorMsgForLastName}</p>
                  ) : item.label === "Middle name" ? (
                    <p className="text-red-500">{errorMsgForMiddleName}</p>
                  ) : item.label === "First name" ? (
                    <p className="text-red-500">{errorMsgForFirstName}</p>
                  ) : item.label === "Email" ? (
                    <p className="text-red-500">{errorMsgForEmail}</p>
                  ) : item.label === "Phone number" ? (
                    <p className="text-red-500">{errorMsgForPhoneNumber}</p>
                  ) : item.label === "Password" ? (
                    <p className="text-red-500">{errorMsgForPassword}</p>
                  ) : (
                    ""
                  )}
                </div>
              );
            }
            if (item.label !== "Password") {
              return (
                <div key={item.label}>
                  <label className="block" htmlFor={item.label}>
                    {item.label}
                    {": "}
                    <span
                      className={`${
                        item.label === "First name" ||
                        item.label === "Last name" ||
                        item.label === "Email" ||
                        item.label === "Password" ||
                        item.label === "Meet link" ||
                        item.label === "Phone number"
                          ? "text-red-600"
                          : "hidden"
                      }`}
                    >
                      *
                    </span>
                  </label>
                  <input
                    type={
                      item.name === "activeSince"
                        ? "date"
                        : item.name === "phone"
                        ? "number"
                        : "text"
                    }
                    id={item.label}
                    name={item.name}
                    value={item.value}
                    required={
                      item.label === "First name" ||
                      item.label === "Last name" ||
                      item.label === "Email" ||
                      item.label === "Password" ||
                      item.label === "Meet link" ||
                      item.label === "Phone number"
                        ? true
                        : false
                    }
                    className=" border w-full border-black outline-0 p-1"
                    onChange={(event) => setInputFields(event)}
                    onBlur={handleBlur}
                  />
                  {item.label === "Last name" ? (
                    <p className="text-red-500">{errorMsgForLastName}</p>
                  ) : item.label === "First name" ? (
                    <p className="text-red-500">{errorMsgForFirstName}</p>
                  ) : item.label === "Middle name" ? (
                    <p className="text-red-500">{errorMsgForMiddleName}</p>
                  ) : item.label === "Email" ? (
                    <p className="text-red-500">{errorMsgForEmail}</p>
                  ) : item.label === "Phone number" ? (
                    <p className="text-red-500">{errorMsgForPhoneNumber}</p>
                  ) : item.label === "Password" ? (
                    <p className="text-red-500">{errorMsgForPassword}</p>
                  ) : (
                    ""
                  )}
                </div>
              );
            }
            return null;
          })}
          <label htmlFor="coachDOB">DOB:</label>
          <input
            type="date"
            id="coachDOB"
            name="dob"
            value={dob}
            className="border border-black outline-0 mb-1 p-1"
            onChange={(event) => setInputFields(event)}
          />
          <p className="text-red-500">{errorMsgForDob}</p>
          <label htmlFor="gender">Gender:</label>
          <select
            id="gender"
            value={gender}
            className="border border-black outline-0 p-1"
            name="gender"
            onChange={(event) => setInputFields(event)}
          >
            <option value="">Select gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="other">Other</option>
          </select>
          {isMeetLinkInputActive || meet_link ? (
            <div className="mt-2">
              {" "}
              <label className="block" htmlFor="meet_link">
                Meet link
              </label>
              <input
                className=" border w-full border-black outline-0 mb-1 p-1"
                type="text"
                id="meet_link"
                name="meet_link"
                ref={meetLinkRef}
                value={meet_link}
                onChange={(event) => setInputFields(event)}
                readOnly={isMeetLinkInputActive ? false : true}
              />
            </div>
          ) : (
            <></>
          )}

          {isEdit && (
            <div>
              <Button
                htmlType="button"
                type="primary"
                className="w-[22ch] mt-4 text-center"
                title={
                  Boolean(email)
                    ? ""
                    : "Enter the email first to generate meet link"
                }
                onClick={
                  generatingMeetLink.loading
                    ? undefined
                    : () => {
                        createMeetLink({ email });
                        setIsMeetLinkInputActive(false);
                      }
                }
                disabled={
                  Boolean(email) && !Boolean(errorMsgForEmail) ? false : true
                }
              >
                {generatingMeetLink.loading
                  ? "Loading..."
                  : "Generate Meet Link"}
              </Button>
              <Button
                onClick={() => {
                  setCoachDetails((prev) => ({ ...prev, meet_link: "" }));
                  setIsMeetLinkInputActive(true);
                  setGeneratingMeetLink({
                    loading: false,
                    error: false,
                  });
                }}
                className="ml-4"
                type="secondary"
              >
                Add Meet Link Manually
              </Button>
            </div>
          )}

          {generatingMeetLink.error && (
            <p className="text-red-500">
              Failed to generate meet link for the coach.
            </p>
          )}
          <label htmlFor="status" className="mt-2 flex gap-2 items-center">
            Status:
            <input
              type="checkbox"
              id="status"
              name="isActive"
              checked={isActive}
              className="cursor-pointer"
              onChange={(event) => setInputFields(event, "checkbox")}
            />
            {isActive === true ? "Active" : "Inactive"}
          </label>

          <div className="text-right my-4 ">
            <Button
              type="secondary"
              onClick={() => handleClose(initialState)}
              className="mr-4"
            >
              Cancel
            </Button>
            <button className="px-4 py-1 border border-primary-ctc text-white bg-primary-ctc rounded">
              {isEdit ? "Save" : "Submit"}
            </button>
          </div>
        </form>
      </section>
    </>,
    document.getElementById("portal")
  );
};

export { AddNewCoach };
