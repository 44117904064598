import { Button, Input, Space, Table, Tag } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { useDispatch, useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { format } from "date-fns";
import {
  getConfirmedSlotsByEventId,
  setExpandedRowKeys,
} from "redux-management";
import { MdDelete } from "assets/icons/icons";
import { Loader } from "components";
import { FaDownload } from "react-icons/fa";

const STATUSES = ['Ongoing','Completed']

const ConfirmedSessionsTable = ({ setDeleteSlotDetails }) => {
  const { eventsList, confirmedSlotsByEventId } = useSelector(
    (state) => state.manageEvents
  );
  const [status, setStatus] = useState(STATUSES[0]);
  const { allCoachesList } = useSelector((state) => state.manageCoaches);
  const { expandedRowKeys } = useSelector((state) => state.confirmedSessions);
  const { confirmedSlotsByEventIdStatus } = useSelector(
    (state) => state.manageEvents
  );
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const expandedRowRender = () => {
    const coachList = confirmedSlotsByEventId?.reduce((acc, curr) => {
      const coachName = allCoachesList.find(
        (coach) => Number(curr.slot.coach_id) === coach.id
      );
      if (expandedRowKeys) {
        return [
          ...acc,
          {
            coachName: `${coachName?.first_name} ${coachName?.middle_name} ${coachName?.last_name}`,
            learnerName: curr.name,
            learnerEmail: curr.email,
            learnerConfirmedSlotId: curr.id,
            coachConfirmedSlotId: curr.slot.id,
            time: curr.slot.start_time,
            date: format(new Date(curr.slot.date), "dd-MM-yyyy"),
            status: curr.status,
          },
        ];
      }
      return acc;
    }, []);

    const columns = [
      {
        title: "Coach Name",
        dataIndex: "coachName",
        key: "coachName",
        width: 180,
      },
      {
        title: "Learner Name",
        dataIndex: "learnerName",
        key: "learnerName",
        width: 180,
      },
      {
        title: "Learner Email",
        dataIndex: "learnerEmail",
        key: "learnerEmail",
        width: 180,
      },
      {
        title: "Session Time",
        dataIndex: "time",
        key: "time",
        width: 160,
        sorter: (a, b) => {
          if (a.time < b.time) {
            return -1;
          }
          if (a.time > b.time) {
            return 1;
          }
          return 0;
        },
        render: (time) => {
          const timeString = format(new Date(+time), "hh:mm a").toUpperCase();
          if (timeString > "12:59") {
            let hour = timeString.slice(0, 2);
            hour = Number(hour) - 12;
            let minute = timeString.slice(2);
            return (
              <p>
                {hour}${minute}
              </p>
            );
          }
          return <p>{timeString}</p>;
        },
      },
      {
        title: "Session Date",
        dataIndex: "date",
        key: "date",
        width: 160,
      },
      {
        title: "Session Status",
        dataIndex: "status",
        key: "status",
        width: 160,
        render: (status) =>
          status === "null" ? (
            <span className="text-gray-400">N/A</span>
          ) : (
            <Tag color={status === "Session completed" ? "green" : "red"}>
              {status}{" "}
            </Tag>
          ),
      },
      {
        title: "Delete",
        dataIndex: "delete",
        key: "delete",
        width: 120,
        render: (_, slot) => {
          return (
            <MdDelete
              className="text-xl cursor-pointer"
              onClick={() => setDeleteSlotDetails(slot)}
            />
          );
        },
      },
    ];

    return confirmedSlotsByEventIdStatus === "pending" ? (
      <Loader />
    ) : (
      <Table
        className="my-2"
        columns={columns}
        dataSource={coachList}
        pagination={false}
        scroll={{
          x: 850,
          y: 200,
        }}
      />
    );
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      ...getColumnSearchProps("name"),
    },
    {
      width: "10%",
      render: (_, record) => (
        <a
          href={`${process.env.REACT_APP_BASE_URL}/export-confirmed-slot/${record.id}`}
        >
          <FaDownload />
        </a>
      ),
    },
  ];

  // adding key to events and reversing it
  let events = eventsList
    .map((event) => ({ ...event, key: event.id }))
    .reverse();

  // filtering active events
  const filteredEvents = events.filter((event) => {
		if(status === "Ongoing"){
    	if (event.end_date >= format(new Date(), "yyyy-MM-dd")) {
      	return true;
    	}	
		}else{
			if (event.end_date < format(new Date(), "yyyy-MM-dd")) {
      return true;
    }
		}
		return false
	});

  useEffect(() => {
    if (expandedRowKeys) {
      dispatch(getConfirmedSlotsByEventId(expandedRowKeys));
    }
  }, [dispatch, expandedRowKeys]);

  return (
    <>
      <h2 className="text-lg font-semibold">Confirmed Sessions :</h2>
      <div className="flex flex-wrap border-b-2 border-b-tab">
				{STATUSES.map(item => <p
          className={`py-2 w-[50%] cursor-pointer ${
            item === status ? "bg-tab text-white" : "bg-slate-200"
          } text-center`}
          onClick={() => setStatus(item)}
        >
          {item}
        </p>)}
      </div>
      <Table
        className="my-4 border-2"
        dataSource={filteredEvents}
        columns={columns}
        pagination={{
          pageSize: 30,
        }}
        expandable={{
          expandedRowRender,
        }}
        onExpand={(expanded, record) => {
          if (expanded === true) {
            dispatch(setExpandedRowKeys(record.id));
          } else {
            dispatch(setExpandedRowKeys(null));
          }
        }}
        expandedRowKeys={[expandedRowKeys]}
        scroll={{
          y: 450,
        }}
      />
    </>
  );
};

export { ConfirmedSessionsTable };
