import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Tag, Tooltip } from "antd"; // Import Tooltip component from Ant Design
import moment from "moment";
import { Button, Modal, List } from "antd";
import { EyeOutlined } from "@ant-design/icons";

const getEmailData = (setEmailData) => {
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/emails-data/`)
    .then((response) => {
      setEmailData(response.data);
    })
    .catch((error) => {
      console.error("Error fetching email data:", error);
      setEmailData([]);
    });
};

const EmailData = () => {
  const [emailData, setEmailData] = useState([]);
  const [modalVisible, setModalVisible] = useState(null);
  const [cancelScehduleMail, setCancelScehduleMail] = useState(null);

  const closeModal = () => {
    setModalVisible(false);
  };

  const closeCancelScheduleMail = () => {
    setCancelScehduleMail(null);
  };

  const handleCancelScheduleMail = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/cancel-schedule-email/${cancelScehduleMail.id}`
      );
      getEmailData(setEmailData);
      setCancelScehduleMail(null);
    } catch (err) {
      console.log("error", err);
    }
  };

  const columns = [
    {
      title: "Sno",
      dataIndex: "sno",
      key: "sno",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Recipients",
      dataIndex: "recipients",
      key: "recipients",
      render: (recipients) => `${Object.keys(recipients).length} recipients`,
    },
    {
      title: "Scheduled For",
      dataIndex: "scheduled_for",
      key: "scheduled_for",
      render: (scheduled_for) =>
        moment(scheduled_for).format("DD/MM/YYYY h:mm A"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let color, text;
        switch (status) {
          case "cancelled":
            color = "red";
            text = "Cancelled";
            break;
          case "completed":
            color = "green";
            text = "Sent";
            break;
          case "pending":
            color = "blue";
            text = "Scheduled";
            break;
          default:
            color = "default";
            text = "Unknown";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: "Template",
      dataIndex: "template",
      key: "template",
      render: (_, rowData) =>
         rowData?.template?.title ?  rowData?.template?.title : <Tag>N/A</Tag>,
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      render: (subject) => (
        <Tooltip title={subject}>
          {subject?.length <= 10 ? subject : subject?.slice(0, 10) + "..."}
        </Tooltip>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex gap-2 flex-nowrap">
          <Button
            type="primary"
            icon={<EyeOutlined />}
            onClick={() => setModalVisible(record)}
          >
            View Emails
          </Button>
          {record.status === "pending" && (
            <Button
              type="primary"
              icon={<EyeOutlined />}
              onClick={() => setCancelScehduleMail(record)}
            >
              Cancel schedule
            </Button>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    getEmailData(setEmailData);
  }, []);

  return (
    <main className="xl:col-start-2 pt-4 xl:col-end-7 w-[98%] mx-auto flex-container flex-column">
      <Table dataSource={emailData} columns={columns} />
      <Modal
        title="Recipient Emails"
        visible={modalVisible}
        onCancel={closeModal}
        footer={null}
        className="w-96" // Set the width of the modal using Tailwind CSS
      >
        <ul className="list-disc pl-4">
          {modalVisible?.recipients?.map((item, index) => (
            <li key={item.email} className="">
              {item.name} : {item.email}
            </li>
          ))}
        </ul>
      </Modal>
      <Modal
        title="Cancel schedule email"
        visible={cancelScehduleMail}
        onCancel={closeCancelScheduleMail}
        onOk={handleCancelScheduleMail}
        className="w-96" // Set the width of the modal using Tailwind CSS
      >
        <p>
          Are you sure you want to cancel the schedule mail with subject:{" "}
          {cancelScehduleMail?.subject}?
        </p>
      </Modal>
    </main>
  );
};

export default EmailData;
