import { MdAdd } from "assets/icons/icons";
import { SlotWrapper } from "components";
import { useDispatch } from "react-redux";
import { setAlert } from "redux-management";

const DayWrapper = ({ day, slotData, dispatchSlotData, allTimeSlots }) => {
  const dispatch = useDispatch();

  let time = allTimeSlots[allTimeSlots.length - 2];
  let timeArray = time.split(":");
  if (Number(timeArray[0]) > 12) {
    let hour = Number(timeArray[0]) - 12;
    timeArray[0] = hour.toString();
  }

  return (
    <div className="flex flex-wrap gap-2 md:gap-8 lg:gap-12 w-fit items-start border-b-[1px] py-4 ">
      <span className="p-2 inline-block w-28 font-semibold">{day}:</span>
      <div className="flex flex-col gap-2 grow">
        {slotData[day]?.length > 0 ? (
          slotData[day]?.map((slot, index) => (
            <SlotWrapper
              key={slot.id}
              day={day}
              index={index}
              slot={slot}
              allTimeSlots={allTimeSlots}
              allSlotsForTheDate={slotData[day]}
              dispatchSlotData={dispatchSlotData}
            />
          ))
        ) : (
          <p className="p-2 text-gray-300">Unavailable</p>
        )}
      </div>
      <button
        onClick={() => {
          let sortedSlots = slotData[day]?.sort(
            (a, b) => a.startTime?._d?.getTime() - b.startTime?._d?.getTime()
          );
          if (
            sortedSlots[sortedSlots.length - 1].endTime._d.toLocaleTimeString(
              [],
              {
                hour12: false,
                hour: "2-digit",
                minute: "2-digit",
              }
            ) >= "20:30"
          ) {
            return dispatch(
              setAlert({
                message: `Cannot add new slot if the end-time is more than or equal to ${timeArray.join(
                  ":"
                )} pm`,
                isAlert: true,
                status: "failure",
              })
            );
          }
          dispatchSlotData({
            type: "ADD_SLOT",
            payload: day,
          });
        }}
        className="p-2 text-2xl text-gray-600"
      >
        <MdAdd />
      </button>
    </div>
  );
};

export { DayWrapper };
