import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const login = createAsyncThunk(
  "authentication/login",
  async (
    { loginDetails, navigate, setAlert },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/login/`,
        data: { ...loginDetails, type: "admin" },
      });
      if (response.status === 200) {
        sessionStorage.setItem("token", response?.data?.token);
        navigate("/coach-request-availability");
        dispatch(
          setAlert({
            message: "Login successful",
            isAlert: true,
            status: "success",
          })
        );
        return { response: response.data };
      }
    } catch (error) {
      if (error.response.status === 404) {
        return dispatch(
          setAlert({
            message: "Invalid login credentials. No user found!",
            isAlert: true,
            status: "failure",
          })
        );
      }
      if (error.response.status === 401) {
        return dispatch(
          setAlert({
            message:
              "Password that you entered is incorrect. Please try again.",
            isAlert: true,
            status: "failure",
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);
