import { Button } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteEventModal } from "./delete-event-modal/DeleteEventModal";
import { EventForm } from "./event-form/EventForm";
import { EventTable } from "./event-table/EventTable";
import { getBatchesList, getCoaches, getEventsList } from "redux-management";
import { Loader } from "components";

const ManageEvents = () => {
  const [createEvent, setCreateEvent] = useState(false);
  const [editEventDetails, setEditEventDetails] = useState(null);
  const [deleteEventDetails, setDeleteEventDetails] = useState(null);
  const [eventExpired, setEventExpired] = useState(false);
  const scrollRef = useRef();
  const { manageEventsStatus } = useSelector((state) => state.manageEvents);
  const dispatch = useDispatch();

  // scrolling to create request if it is active
  useEffect(() => {
    if (createEvent) {
      window.scrollTo(0, scrollRef.current.offsetTop - 60);
    }
  }, [createEvent]);

  useEffect(() => {
    dispatch(getEventsList());
    dispatch(getCoaches());
    dispatch(getBatchesList());
  }, [dispatch]);

  return (
    <main className="xl:col-start-2 pt-4 xl:col-end-7 w-[98%] mx-auto">
      {manageEventsStatus === "pending" ? (
        <Loader />
      ) : manageEventsStatus === "rejected" ? (
        <h2 className="text-xl text-center">
          There is a glitch, please reload the page.
        </h2>
      ) : (
        <>
          <div className="flex flex-wrap mb-2 border-b-2 border-b-tab">
            <p
              className={`py-2 w-[50%] cursor-pointer ${
                eventExpired ? "bg-slate-200" : "bg-tab text-white"
              } text-center`}
              onClick={() => setEventExpired(false)}
            >
              Active
            </p>
            <p
              className={`py-2 w-[50%] cursor-pointer ${
                eventExpired ? "bg-tab text-white" : "bg-slate-200"
              } text-center`}
              onClick={() => setEventExpired(true)}
            >
              In Active
            </p>
          </div>
          <EventTable
            eventExpired={eventExpired}
            setEditEventDetails={setEditEventDetails}
            setDeleteEventDetails={setDeleteEventDetails}
            setCreateEvent={setCreateEvent}
          />
          {!createEvent && (
            <Button
              className="block mx-auto"
              type="primary"
              onClick={() => setCreateEvent(true)}
            >
              Create Event
            </Button>
          )}
          {createEvent && (
            <>
              <div ref={scrollRef} className="h-4"></div>
              <h3 className="text-xl">Create Event:</h3>
              <EventForm
                setCreateEvent={setCreateEvent}
                editEventDetails={editEventDetails}
                setEditEventDetails={setEditEventDetails}
              />
            </>
          )}
          {deleteEventDetails && (
            <DeleteEventModal
              deleteEventDetails={deleteEventDetails}
              setDeleteEventDetails={setDeleteEventDetails}
            />
          )}
        </>
      )}
    </main>
  );
};

export { ManageEvents };
