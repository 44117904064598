import { React, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { Input, Button, Space, Checkbox, Avatar, Tooltip } from "antd";
import axios from "axios";
import { EditOutlined, ArrowLeftOutlined } from "@ant-design/icons";

const PreviewPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const template = location.state || {};
  const [valueSubject, setValueSubject] = useState("");
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isSentMailLoading, setIsSentMailLoading] = useState(false);

  const postData = async (email, htmlContent, url) => {
    setIsSentMailLoading(true);
    try {
      const response = await axios({
        method: "POST",
        url,
        data: {
          emails: [email],
          htmlContent: template?.template_data,
          subject: valueSubject,
        },
      });
      setIsSentMailLoading(false);
      navigate("/successtestpage", {
        state: { template: template, subject: valueSubject },
      });
      return response;
    } catch (error) {
      setIsSentMailLoading(false);
      if (error.response?.data?.email) {
        alert("Email not found!");
      } else {
        alert("Something went wrong");
      }
    }
  };

  const handleSubmit = async () => {
    if (!isEmailValid || !email) {
      if (!isEmailValid) {
        alert("Please enter a valid email address.");
      } else {
        alert("Please enter an email address.");
      }
      return;
    }

    postData(
      email,
      template.template_data,
      `${process.env.REACT_APP_BASE_URL}/send_test_mails/`
    );
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsEmailValid(validateEmail(e.target.value));
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <main className="xl:col-start-2 pt-4 xl:col-end-7 w-[98%] mx-auto">
      <div className="flex p-2 border-b-2 border-gray">
        <div>
          <ArrowLeftOutlined onClick={() => navigate(-1)} />
        </div>
        <div className="text-lg font-semibold px-2">Test Email</div>
        <div className="ml-auto">
          <Tooltip
            title={
              email.length === 0 && valueSubject.length === 0
                ? "Enter email and subject"
                : "" || email.length === 0
                ? "Enter email"
                : "" || valueSubject.length === 0
                ? "Enter subject"
                : ""
            }
          >
            <Button
              disabled={email.length === 0 || valueSubject.length === 0}
              type="primary"
              loading={isSentMailLoading}
              onClick={handleSubmit}
            >
              Send Test Mails
            </Button>
          </Tooltip>
        </div>
      </div>
      <div className="flex gap-5">
        <div className="px-8">
          <div className="flex gap-4 mt-[.5rem]">
            <div>
              <div className="mb-[.5rem] flex mt-2">
                <span className="text-lg inline-block w-[5rem]">From: </span>
                <span>
                  <Input
                    placeholder="Add email here..."
                    disabled={true}
                    value={"info@mail.meeraq.com.com"}
                    className={
                      !isEmailValid ? "invalid-email" : "" + "rounded-md"
                    }
                  />
                </span>
                {/* <span className="text-lg">{"sreerag@gmail.com"}</span> */}
              </div>
              <div className="flex mb-[.5rem]">
                <span className="text-lg inline-block w-[5rem]">
                  To <span className="text-red-500">*</span>:
                </span>
                <span>
                  <Input
                    placeholder="Add email here..."
                    value={email}
                    onChange={handleEmailChange}
                    className={
                      !isEmailValid ? "invalid-email" : "" + "rounded-md"
                    }
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="flex mb-[1rem]">
            <div className="text-lg inline-block w-[5rem]">
              Subject:
              <span className="text-red-500">*</span>:
            </div>{" "}
            <Input
              placeholder="Add subject..."
              value={valueSubject}
              onChange={(e) => setValueSubject(e.target.value)}
              className={"w-[33.3%] rounded-md"}
              maxLength={200}
            />
            <div />
          </div>
          <div className="border-2">
            <div dangerouslySetInnerHTML={{ __html: template.template_data }} />
          </div>
        </div>
      </div>
    </main>
  );
};

export { PreviewPage };
