import { Table, Tag } from "antd";
import { Header, LoadingAndError } from "components";
import { format } from "date-fns";
import { useGetApi } from "hooks/useGetApi";
import React, { useMemo } from "react";

const dummyData = [
  {
    id: 1,
    slot: {
      id: 101,
      coach_id: "101",
      start_time: "1696014000000",
      end_time: "1696015800000",
      date: "2023-09-30",
      request_ID: "101",
      SESSION_START_TIME: "09:00 AM",
      SESSION_END_TIME: "09:30 AM",
      SESSION_DATE: "30 September 2023",
      COACH_NAME: "John Doe",
      DESCRIPTION: "Sample session 1",
      CC: "john@meeraq.com",
      MEETING_LINK: "https://coach.meeraq.com/join-session/101",
      is_confirmed: true,
      is_released: false,
    },
    name: "Alice",
    email: "alice@meeraq.com",
    phone_no: "1234567890",
    organisation: "Meeraq",
    status: "null",
    event: 1,
    coach: {
      id: 101,
      first_name: "John",
      middle_name: "",
      last_name: "Doe",
      email: "john@meeraq.com",
      phone: "9876543210",
      dob: "1990-01-01",
      gender: "Male",
      fee: 500,
      activeSince: "2023-09-15",
      isSlotBooked: false,
      isActive: true,
      meet_link: "https://coach.meeraq.com/join-session/101",
    },
    batch: "meeraq_team",
  },
  {
    id: 2,
    slot: {
      id: 102,
      coach_id: "102",
      start_time: "1696017600000",
      end_time: "1696019400000",
      date: "2023-10-01",
      request_ID: "102",
      SESSION_START_TIME: "10:00 AM",
      SESSION_END_TIME: "10:30 AM",
      SESSION_DATE: "1 October 2023",
      COACH_NAME: "Jane Smith",
      DESCRIPTION: "Sample session 2",
      CC: "jane@meeraq.com",
      MEETING_LINK: "https://coach.meeraq.com/join-session/102",
      is_confirmed: true,
      is_released: false,
    },
    name: "Bob",
    email: "bob@meeraq.com",
    phone_no: "9876543210",
    organisation: "Meeraq",
    status: "null",
    event: 2,
    coach: {
      id: 102,
      first_name: "Jane",
      middle_name: "",
      last_name: "Smith",
      email: "jane@meeraq.com",
      phone: "1234567890",
      dob: "1985-05-15",
      gender: "Female",
      fee: 600,
      activeSince: "2023-09-20",
      isSlotBooked: false,
      isActive: true,
      meet_link: "https://coach.meeraq.com/join-session/102",
    },
    batch: "meeraq_team",
  },
  {
    id: 1,
    slot: {
      id: 8,
      coach_id: "2",
      start_time: "1695907800000",
      end_time: "1695909600000",
      date: "2023-09-28",
      request_ID: "3",
      SESSION_START_TIME: "09:00 AM",
      SESSION_END_TIME: "09:30 AM",
      SESSION_DATE: "27 September 2023",
      COACH_NAME: "Priyal Jain",
      DESCRIPTION: "testing 3",
      CC: "priyal@meeraq.com",
      MEETING_LINK:
        "https://coach.meeraq.com/join-session/64dc9ff2eba04ea86841ec57",
      is_confirmed: true,
      is_released: false,
    },
    name: "Sreerag",
    email: "sreerag@meeraq.com",
    phone_no: "7484748596",
    organisation: "Meeraq",
    status: "null",
    event: 2,
    coach: {
      id: 2,
      first_name: "Priyal",
      middle_name: "",
      last_name: "Jain",
      email: "priyal@meeraq.com",
      phone: "7845963210",
      dob: "2000-01-01",
      gender: "Female",
      fee: 450,
      activeSince: "2023-09-19",
      isSlotBooked: false,
      isActive: true,
      meet_link:
        "https://coach.meeraq.com/join-session/64dc9ff2eba04ea86841ec57",
    },
    batch: "meeraq_team",
  },
  {
    id: 1,
    slot: {
      id: 101,
      coach_id: "101",
      start_time: "1696014000000",
      end_time: "1696015800000",
      date: "2023-09-30",
      request_ID: "101",
      SESSION_START_TIME: "09:00 AM",
      SESSION_END_TIME: "09:30 AM",
      SESSION_DATE: "30 September 2023",
      COACH_NAME: "John Doe",
      DESCRIPTION: "Sample session 1",
      CC: "john@meeraq.com",
      MEETING_LINK: "https://coach.meeraq.com/join-session/101",
      is_confirmed: true,
      is_released: false,
    },
    name: "Alice",
    email: "alice@meeraq.com",
    phone_no: "1234567890",
    organisation: "Meeraq",
    status: "null",
    event: 1,
    coach: {
      id: 101,
      first_name: "John",
      middle_name: "",
      last_name: "Doe",
      email: "john@meeraq.com",
      phone: "9876543210",
      dob: "1990-01-01",
      gender: "Male",
      fee: 500,
      activeSince: "2023-09-15",
      isSlotBooked: false,
      isActive: true,
      meet_link: "https://coach.meeraq.com/join-session/101",
    },
    batch: "meeraq_team",
  },
];

export const UpcomingSessions = () => {
  const { data, isLoading, error } = useGetApi(
    `${process.env.REACT_APP_BASE_URL}/upcoming-sessions/`
  );

  const columns = [
    {
      title: "Coach Name",
      dataIndex: "coach",
      key: "coach",
      // width: 180,
      render: (coach) =>
        coach
          ? coach.first_name + " " + coach.middle_name + " " + coach.last_name
          : "",
    },
    {
      title: "Learner Name",
      dataIndex: "name",
      key: "name",
      // width: 180,
    },
    {
      title: "Learner Email",
      dataIndex: "email",
      key: "email",
      // width: 180,
    },
    {
      title: "Batch",
      dataIndex: "batch",
      key: "batch",
      // width: 180,
    },
    {
      title: "Session Time",
      dataIndex: "time",
      key: "time",
      // width: 160,
      sorter: (a, b) => {
        if (a.time < b.time) {
          return -1;
        }
        if (a.time > b.time) {
          return 1;
        }
        return 0;
      },
      render: (time) => {
        const timeString = format(new Date(+time), "hh:mm a").toUpperCase();
        if (timeString > "12:59") {
          let hour = timeString.slice(0, 2);
          hour = Number(hour) - 12;
          let minute = timeString.slice(2);
          return (
            <p>
              {hour}${minute}
            </p>
          );
        }
        return <p>{timeString}</p>;
      },
    },
    {
      title: "Session Date",
      dataIndex: "date",
      key: "date",
      width: 160,
    },
  ];

  const dataSource = useMemo(() => {
    if (data) {
      return data?.data.map((item) => ({
        ...item,
        time: item.slot.start_time,
        date: format(new Date(item.slot.date), "dd-MM-yyyy"),
      }));
    }
    return [];
  }, [data]);

  return (
    <main className="xl:col-start-2 pt-4 xl:col-end-7 w-[98%] mx-auto">
      <LoadingAndError error={error} loading={isLoading} data={Boolean(data)}>
        {data && (
          <>
            <div className="bg-gray-400/10 text-center font-semibold py-2 mb-2">
              Upcoming Sessions
            </div>
            <Table
              className="border"
              columns={columns}
              dataSource={dataSource}
            />
          </>
        )}
      </LoadingAndError>
    </main>
  );
};
