export const removeEmptyPropertiesFromObject = (object) => {
  let keys = Object.keys(object);
  const result = keys.reduce((acc, curr) => {
		if (curr === 'isActive') {
      return { ...acc, [curr]: object[curr] };
    }
    if (object[curr]) {
      return { ...acc, [curr]: object[curr] };
    }
    return acc;
  }, {});
  return result;
};
