import { Email } from "pages/email/Email";
const EmailSupport = () => {
  return (
    <main className="xl:col-start-2 pt-4 xl:col-end-7 w-[98%] mx-auto">
      <Email />
    </main>
  );
};

export { EmailSupport };
