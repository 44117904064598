import { Table, Tag } from "antd";
import React, { useEffect } from "react";
import { AiOutlineClose } from "assets/icons/icons";
import { useSelector } from "react-redux";

const SingleRequestModal = ({ request, setRequest }) => {
  const { confirmedSlotsByRequestId } = useSelector(
    (state) => state.defineCoachingSessions
  );
  const { allCoachesList } = useSelector((state) => state.manageCoaches);

  const data = request?.assigned_coach?.reduce((acc, assignedCoachId) => {
    return (acc = [
      ...acc,
      {
        assigned_coach: assignedCoachId,
        confirmed_coach: request?.confirmed_coach?.find(
          (confirmedCoachId) => confirmedCoachId === assignedCoachId
        ),
        no_of_slots: confirmedSlotsByRequestId?.reduce((acc, confirmedSlot) => {
          return Number(confirmedSlot.coach_id) === assignedCoachId
            ? acc + 1
            : acc;
        }, 0),
      },
    ]);
  }, []);

  // Stop scroll of background page
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const columns = [
    {
      title: "Assigned coach",
      dataIndex: "assigned_coach",
      key: "assigned_coach",
      fixed: "left",
      width: 180,
      render: (assignedCoachId) => {
        let coach = allCoachesList.find(
          (coach) => coach.id === assignedCoachId
        );
        return (
          <p>{`${coach.first_name} ${coach.middle_name} ${coach.last_name}`}</p>
        );
      },
    },
    {
      title: "Action by coach",
      dataIndex: "confirmed_coach",
      key: "confirmed_coach",
      width: 180,
      render: (confirmedCoachId) => {
        if (confirmedCoachId) {
          return <Tag color="green">Provided slots</Tag>;
        } else {
          return <Tag color="red">Not provided yet!</Tag>;
        }
      },
    },
    {
      title: "No of slots provided by coach",
      dataIndex: "no_of_slots",
      key: "no_of_slots",
      fixed: "right",
      width: 100,
    },
  ];

  const totalNoOfSlotsConfirmedByCoaches = data.reduce((acc, curr) => {
    return (acc = acc + curr.no_of_slots);
  }, 0);

  const handleClose = ({ setRequest }) => {
    setRequest(null);
  };

  return (
    <>
      <div
        className="fixed inset-0 z-modal-bg bg-slate-300 opacity-70"
        onClick={() => handleClose({ setRequest })}
      />
      <section className="py-4 px-12 z-modal w-11/12 sm:w-9/12 lg:w-2/3 border border-zinc-400 rounded fixed top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 bg-white">
        <h2 className="text-2xl my-3 font-medium text-center">
          {request.name}
        </h2>
        <h3 className="text-lg mt-4 font-medium">
          Total no of slots provided by all coaches :{" "}
          <span className="font-light">{totalNoOfSlotsConfirmedByCoaches}</span>
        </h3>
        <Table
          className="my-4 border-2"
          dataSource={data}
          columns={columns}
          pagination={{
            pageSize: 50,
          }}
          scroll={{
            x: 460,
            y: 400,
          }}
        />
        <AiOutlineClose
          className="text-2xl absolute top-4 right-2 cursor-pointer"
          onClick={() => handleClose({ setRequest })}
        />
      </section>
    </>
  );
};

export { SingleRequestModal };
