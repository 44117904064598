import { Button } from "antd";
import React from "react";
import { writeFile, utils } from "xlsx";
import { DownloadOutlined } from "@ant-design/icons";

export const BatchSampleExcel = ({ data, title, disabled }) => {
  const formattedData = data.map((item) => ({
    first_name: item.first_name,
    last_name: item.last_name,
    email: item.email,
    batch: item.batch,
    phone: item.phone,
    course: item.course,
    start_date: item.start_date,
    end_date: item.end_date,
  }));

  const handleClick = () => {
    // create sample data
    const data = formattedData;
    // create workbook and worksheet
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(data);
    // set column headers
    ws["A1"] = { t: "s", v: "first_name" };
    ws["B1"] = { t: "s", v: "last_name" };
    ws["C1"] = { t: "s", v: "email" };
    ws["D1"] = { t: "s", v: "batch" };
    ws["E1"] = { t: "s", v: "phone" };
    ws["F1"] = { t: "s", v: "course" };
    ws["G1"] = { t: "s", v: "start_date" };
    ws["H1"] = { t: "s", v: "end_date" };
    // add worksheet to workbook
    utils.book_append_sheet(wb, ws, "Sheet1");
    // save file
    writeFile(wb, "batch_sample_for_schedular.xlsx");
  };

  return (
    <div>
      <Button
        disabled={disabled}
        icon={<DownloadOutlined />}
        onClick={handleClick}
        type="secondary"
      >
        <span className="">{title}</span>
      </Button>
    </div>
  );
};
