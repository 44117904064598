import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "antd";
import { ContentEditable } from "react-contenteditable";
import template from "./initialEmailContent";

const Email = ({}) => {
  const location = useLocation();
  const { templatePart } = location.state || {};

  const [htmlContent, setHtmlContent] = useState(templatePart);

  const handleContentChange = (event) => {
    setHtmlContent(event.target.value);
  };

  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate("/preview", { state: { htmlContent } });
  };

  useEffect(() => {
    if (!htmlContent) {
      navigate("/templates");
    }
  }, [htmlContent]);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <textarea
          value={htmlContent}
          onChange={handleContentChange}
          rows={10}
          style={{ margin: "10px 0" }}
        />
        <ContentEditable
          html={htmlContent}
          onChange={(event) => setHtmlContent(event.target.value)}
        />
      </div>

      <Button type="primary" onClick={handleSubmit}>
        Test
      </Button>
    </>
  );
};

export { Email };
