import React, { useState } from "react";
import { GiHamburgerMenu } from "assets/icons/icons";
import { MobileMenu } from "./mobile-menu/MobileMenu";
import logo from "assets/images/meeraq_logo.png";
import { useNavigate, useLocation } from "react-router-dom";

const Header = () => {
  const [isHamburger, setIsHamburger] = useState(false);
  const isLoggedIn = sessionStorage.getItem("token");
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <header className="px-3 py-1 h-16 sticky top-0 flex flex-wrap  gap-6 items-center bg-white shadow z-header">
      <img className="w-40 mt-2" src={logo} alt="logo" />
      {isLoggedIn && (
        <button
          className="text-primary-ctc ml-auto cursor-pointer text-lg"
          onClick={() => {
            sessionStorage.removeItem("token");
            navigate("/");
          }}
        >
          Logout
        </button>
      )}
      {pathname !== "/" && (
        <GiHamburgerMenu
          className={`xl:hidden ${
            isLoggedIn ? "mr-2" : "ml-auto"
          } cursor-pointer text-primary-ctc text-3xl`}
          onClick={() => setIsHamburger(!isHamburger)}
        />
      )}
      <MobileMenu isHamburger={isHamburger} setIsHamburger={setIsHamburger} />
    </header>
  );
};

export { Header };
