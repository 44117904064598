import { Button } from "antd";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { useDispatch } from "react-redux";
import { setAlert, getEventsList, deleteEvent } from "redux-management";

const DeleteEventModal = ({ deleteEventDetails, setDeleteEventDetails }) => {
  const dispatch = useDispatch();

  // Stop scroll of background page
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return ReactDOM.createPortal(
    <>
      <div
        className="fixed inset-0 z-modal-bg bg-slate-300 opacity-70"
        onClick={() => setDeleteEventDetails(null)}
      />
      <section className="py-3 flex flex-col gap-2 px-8 z-modal w-11/12 sm:w-9/12 lg:w-2/3 xl:w-1/3 border border-zinc-400 rounded fixed top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 bg-white">
        <h2 className="text-xl">
          Are you sure to delete the event{" "}
          <span className="font-semibold">{deleteEventDetails.name}</span>?
        </h2>
        <div className="mt-3 mb-2 flex justify-end">
          <Button
            className="mr-4"
            type="secondary"
            onClick={() => {
              dispatch(
                deleteEvent({
                  eventId: deleteEventDetails.id,
                  getEventsList,
                  setAlert,
                })
              );
              setDeleteEventDetails(null);
            }}
          >
            Delete
          </Button>
          <Button type="primary" onClick={() => setDeleteEventDetails(null)}>
            Cancel
          </Button>
        </div>
      </section>
    </>,
    document.getElementById("portal")
  );
};

export { DeleteEventModal };
