import React from "react";
import { ConfirmedSlots } from "./confirmed-slots/ConfirmedSlots";

const ConfirmedSlotsWrapper = ({
  formattedData,
  allDates,
  setDeleteSlotDetails,
  bookedSessionIds,
}) => {
  return (
    <section className="flex flex-col gap-2 mt-2 mb-4 flex-wrap">
      {allDates.length > 0 ? (
        allDates.map((date) => {
          return (
            <ConfirmedSlots
              bookedSessionIds={bookedSessionIds}
              formattedData={formattedData}
              date={date}
              setDeleteSlotDetails={setDeleteSlotDetails}
            />
          );
        })
      ) : (
        <h3 className="text-xl mt-8 font-semibold mx-auto">
          No slots confirmed by coach yet.
        </h3>
      )}
    </section>
  );
};

export { ConfirmedSlotsWrapper };
