import { Button } from "antd";
import React, { useRef } from "react";

const ForgotPassword = () => {
  // const initialState = { password: "", newPassword: "" };
  // const [passwordDetails, setPasswordDetails] = useState({ ...initialState });
  // const { password, newPassword } = passwordDetails;

  const emailRef = useRef(null);
  // const resetPasswordHandler = (event, setPasswordDetails) => {
  //   setPasswordDetails((prev) => ({
  //     ...prev,
  //     [event.target.name]: event.target.value,
  //   }));
  // };

  // const submitHandler = () => {};

  return (
    <main className="w-11/12 mx-auto flex justify-center border">
      <div className="h-full border border-sky-200">
        <h1 className="text-lg text-center font-medium">Forgot Password?</h1>
        <p>
          Please enter you email address to recieve a reset passsword link.{" "}
        </p>
        <form className="">
          <div className="w-[40px] mx-auto">
            <input
              type="email"
              placeholder="email"
              className="border border-black outline-0 p-1 "
              name="email"
              required
              ref={emailRef}
            />
          </div>
          <Button type="primary">Send Link</Button>
        </form>
      </div>
    </main>
  );

  // return (
  //   <main className="w-11/12 lg:w-1/2 mx-auto">
  //     <h1 className="mb-4 text-3xl text-center font-medium">Reset password</h1>
  //     <form className="flex flex-col gap-2">
  //       <input
  //         type="password"
  //         placeholder="Password"
  //         className="border border-black outline-0 p-1"
  //         value={password}
  //         name="password"
  //         required
  //         onChange={(event) => resetPasswordHandler(event, setPasswordDetails)}
  //       />
  //       <p className="text-xs">
  //         Must contain atleast 1 uppercase, 1 lowercase and 1 numeric
  //         characters. Minimum 8 characters.
  //       </p>
  //       <input
  //         type="password"
  //         placeholder="Retype new password"
  //         value={newPassword}
  //         name="newPassword"
  //         required
  //         className="border border-black outline-0 p-1"
  //         onChange={(event) => resetPasswordHandler(event, setPasswordDetails)}
  //       />
  //       <div className="text-right">
  //         <button
  //           className="px-4 py-1 text-xl rounded bg-sky-500 text-white"
  //           onClick={() => submitHandler()}
  //         >
  //           Submit
  //         </button>
  //       </div>
  //     </form>
  //   </main>
  // );
};

export { ForgotPassword };
