import { createSlice } from "@reduxjs/toolkit";
import { login } from "./authServices";

const initialState = {
  userDetails: null,
  authStatus: "idle",
  authError: null,
};

export const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {},
  extraReducers: {
    [login.pending]: (state) => {
      state.authStatus = "pending";
      state.authError = null;
    },
    [login.fulfilled]: (state, { payload: { response } }) => {
      state.authStatus = "fulfilled";
      state.userDetails = response;
    },
    [login.rejected]: (state, action) => {
      state.authStatus = "rejected";
      state.authError = action.payload;
    },
  },
});

export default authSlice.reducer;
