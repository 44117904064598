import { useReducer, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { Button, Space, Input, Table } from "antd";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineFileDownload, MdDelete } from "assets/icons/icons";
import { v4 as uuid } from "uuid";
import {
  setAlert,
  getCoaches,
  getAdminRequests,
  getConfirmedSlotsByRequestId,
} from "redux-management";
import { useEffect } from "react";
import moment from "moment";
import { SingleRequestModal } from "./single-request-modal/SingleRequestModal";
import { DeleteRequestModal } from "./delete-request-modal/DeleteRequestModal";
import { RequestSessionForm } from "./request-session-form/RequestSessionForm";
import { Loader } from "components";

const RequestSessions = () => {
  const slotDataReducer = (state, action) => {
    switch (action.type) {
      case "SET_TIME":
        const { day: currDay, time, id, type } = action.payload;
        return {
          ...state,
          [currDay]: state[currDay].map((slot) => {
            if (slot.id === id) {
              if (type === "START_TIME") {
                return {
                  ...slot,
                  startTime: time,
                };
              }
              if (type === "END_TIME") {
                return {
                  ...slot,
                  endTime: time,
                };
              }
            } else {
              return { ...slot };
            }
            return { ...slot };
          }),
        };
      case "ADD_SLOT":
        return {
          ...state,
          [action.payload]: [
            ...state[action.payload],
            { id: uuid(), startTime: "", endTime: "" },
          ],
        };
      case "RESET_SLOTS":
        return null;
      case "DELETE_SLOT":
        if (action.payload.perDayLength > 1) {
          return {
            ...state,
            [action.payload.day]: state[action.payload.day].filter(
              (slot) => slot.id !== action.payload.id
            ),
          };
        } else {
          setValues((preValue) =>
            preValue.filter(
              (date) =>
                `${new Date(date).toLocaleDateString().split("/")[0]}-${
                  new Date(date).toLocaleDateString().split("/")[1]
                }-${new Date(date).toLocaleDateString().split("/")[2]}` !==
                action.payload.day
            )
          );
          delete state[action.payload.day];
          return { ...state };
        }
      case "ADD_SLOTS":
        return slotsArray;
      default:
        throw new Error();
    }
  };
  const [values, setValues] = useState();
  const [status, setStatus] = useState(true);
  const [request, setRequest] = useState(null);
  const [makeRequest, setMakeRequest] = useState(false);
  const scrollRef = useRef();
  const { defineCoachingSessionsStatus, adminRequests, makeRequestStatus } =
    useSelector((state) => state.defineCoachingSessions);
  const dispatch = useDispatch();

  // setting initial state for selected dates
  const slotsArray = values?.reduce((acc, curr) => {
    return {
      ...acc,
      [format(new Date(curr), "dd-MM-yyyy")]: [
        {
          id: uuid(),
          startTime: "",
          endTime: "",
        },
      ],
    };
  }, {});

  const [slotData, dispatchSlotData] = useReducer(slotDataReducer, slotsArray);
  const [slotsConflictDatesState, setSlotsConflictDatesState] = useState({});
  const [expiryDate, setExpiryDate] = useState("");
  const [
    requestDetailsForDeletingRequest,
    setRequestDetailsForDeletingRequest,
  ] = useState(null);
  const { allCoachesList } = useSelector((state) => state.manageCoaches);

  useEffect(() => {
    for (let i in slotData) {
      let date = i;
      let slotObjects = slotData[date];
      if (slotObjects.length > 1) {
        let result;
        for (let i = 0; i < slotObjects.length; i++) {
          if (
            slotObjects[i].startTime !== "" &&
            slotObjects[i].endTime !== ""
          ) {
            result = true;
          } else {
            result = false;
            break;
          }
        }

        if (result) {
          let obj = [...slotObjects];
          let sortedSlotObjects = obj.sort(
            (a, b) => a.startTime._d.getTime() - b.startTime._d.getTime()
          );
          for (let i = 0; i < sortedSlotObjects.length; i++) {
            for (let j = i + 1; j < sortedSlotObjects.length; j++) {
              if (
                sortedSlotObjects[i].endTime._d.toLocaleTimeString([], {
                  hours12: false,
                  hour: "2-digit",
                  minute: "2-digit",
                }) >=
                sortedSlotObjects[j].startTime._d.toLocaleTimeString([], {
                  hours12: false,
                  hour: "2-digit",
                  minute: "2-digit",
                })
              ) {
                setSlotsConflictDatesState((prev) => ({
                  ...prev,
                  [date]: true,
                }));
                dispatch(
                  setAlert({
                    message: `slots are conflicting on ${date}`,
                    isAlert: true,
                    status: "failure",
                  })
                );
                break;
              } else {
                setSlotsConflictDatesState((prev) => ({
                  ...prev,
                  [date]: false,
                }));
              }
            }
          }
        }
      } else {
        setSlotsConflictDatesState((prev) => ({ ...prev, [date]: false }));
      }
    }
  }, [slotData, dispatch]);

  // scrolling to create request if it is active
  useEffect(() => {
    if (makeRequest) {
      window.scrollTo(0, scrollRef.current.offsetTop - 60);
    }
  }, [makeRequest]);

  // incrementing today's date with 3
  const today = moment();
  const afterThreeDays = moment(today).add(3, "days");
  const threeDaysFromNow = format(afterThreeDays._d, "yyyy-MM-dd");

  // breaking the long time slots into smaller chunks
  const breakLongTimeSlots = (slotData) => {
    const slots = (() => {
      let arr = [];
      for (let key in slotData) {
        slotData[key].forEach((slot) => {
          const { startTime, endTime } = slot;
          if (startTime && endTime) {
            let currTime = startTime._d.getTime();
            let finishTime = endTime._d.getTime();
            // checking currTime + 30mins <= finishTime
            while (currTime + 1800000 <= finishTime) {
              let myDate = key.split("-");
              var newDate = new Date(myDate[2], myDate[1] - 1, myDate[0]);
              arr.push({
                date: format(new Date(newDate), "yyyy-MM-dd"),
                start_time: String(currTime),
                end_time: String(currTime + 1800000),
              });
              // adding 45mins to currTime
              currTime += 2700000;
            }
          }
        });
      }
      return arr;
    })();
    return slots;
  };

  // get all coaches by api call
  useEffect(() => {
    dispatch(getCoaches());
    dispatch(getAdminRequests());
  }, [dispatch]);

  useEffect(() => {
    setExpiryDate(threeDaysFromNow);
  }, [threeDaysFromNow]);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Request name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 180,
      ...getColumnSearchProps("name"),
    },
    {
      title: "Assigned coaches",
      dataIndex: "assigned_coach",
      key: "assigned_coach",
      width: 200,
      render: (assignedCoaches) => <p>{assignedCoaches.length}</p>,
    },
    {
      title: "Action by coaches",
      dataIndex: "confirmed_coach",
      key: "confirmed_coach",
      width: 200,
      render: (confirmedCoaches) => <p>{confirmedCoaches.length}</p>,
    },
    {
      title: "Expiry date",
      dataIndex: "expire_date",
      key: "expire_date",
      width: 120,
      sorter: (a, b) => {
        if (a.expire_date > b.expire_date) {
          return 1;
        }
        if (a.expire_date < b.expire_date) {
          return -1;
        }
        return 0;
      },
      render: (expiryDate) => (
        <p>{format(new Date(expiryDate), "dd-MM-yyyy")}</p>
      ),
    },
    {
      title: "Download",
      dataIndex: "download",
      key: "download",
      width: 100,
      render: (_, request) => {
        if (status) {
          return (
            <a
              className="text-xl hover:text-black"
              href={`${process.env.REACT_APP_BASE_URL}/export-confirmed-slot-data/${request.id}/`}
            >
              <MdOutlineFileDownload />
            </a>
          );
        } else {
          return (
            <MdOutlineFileDownload className="text-xl cursor-not-allowed" />
          );
        }
      },
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      width: 80,
      render: (_, request) => {
        return (
          <MdDelete
            className="text-xl cursor-pointer"
            onClick={() => {
              setRequestDetailsForDeletingRequest({
                id: request.id,
                name: request.name,
              });
            }}
          />
        );
      },
    },
    {
      title: "Report",
      dataIndex: "report",
      key: "report",
      width: 180,
      fixed: "right",
      render: (_, request) => {
        return (
          <Button
            className="rounded"
            type="primary"
            onClick={() => {
              setRequest(request);
              dispatch(getConfirmedSlotsByRequestId(request.id));
            }}
          >
            Click to see more
          </Button>
        );
      },
    },
  ];

  // filtering requests based on isActive property
  const filteredAdminRequests = adminRequests
    ?.filter((request) => request.isActive === status)
    ?.reverse();

  // show alert if make request api call is failed
  useEffect(() => {
    if (makeRequestStatus === "rejected") {
      dispatch(
        setAlert({
          message: "Request failed, please retry again",
          isAlert: true,
          status: "failure",
        })
      );
    }
  }, [makeRequestStatus, dispatch]);

  if (makeRequestStatus === "pending") {
    return (
      <main className="xl:col-start-2 pt-4 xl:col-end-7 w-[98%] mx-auto">
        <Loader />
      </main>
    );
  }

  return (
    <main className="xl:col-start-2 pt-4 xl:col-end-7 w-[98%] mx-auto">
      {defineCoachingSessionsStatus === "pending" ? (
        <Loader />
      ) : defineCoachingSessionsStatus === "rejected" ? (
        <h2 className="text-xl text-center">
          There is a glitch, please reload the page.
        </h2>
      ) : (
        <>
          <Button type="primary" className="block ml-auto mb-3">
            <a
              href={`${process.env.REACT_APP_BASE_URL}/export-all-confirmed-slot-data/`}
            >
              Download coach slots across multiple requests
            </a>
          </Button>
          <div className="flex flex-wrap border-b-2 border-b-tab">
            <p
              className={`py-2 w-[50%] cursor-pointer ${
                status ? "bg-tab text-white" : "bg-slate-200"
              } text-center`}
              onClick={() => setStatus(true)}
            >
              Active
            </p>
            <p
              className={`py-2 w-[50%] cursor-pointer ${
                status ? "bg-slate-200" : "bg-tab text-white"
              } text-center`}
              onClick={() => setStatus(false)}
            >
              Expired
            </p>
          </div>
          <h2 className="mt-4 text-2xl">Request details:</h2>
          <Table
            className="my-2 border-2"
            dataSource={filteredAdminRequests}
            columns={columns}
            pagination={{
              pageSize: 30,
            }}
            scroll={{
              x: 1100,
              y: 290,
            }}
          />
          {!makeRequest && (
            <Button
              type="primary"
              className="mt-4 block mx-auto"
              onClick={() => setMakeRequest(true)}
            >
              Create request
            </Button>
          )}
          {makeRequest && (
            <RequestSessionForm
              dispatchSlotData={dispatchSlotData}
              slotsConflictDatesState={slotsConflictDatesState}
              expiryDate={expiryDate}
              setValues={setValues}
              setMakeRequest={setMakeRequest}
              scrollRef={scrollRef}
              values={values}
              setSlotsConflictDatesState={setSlotsConflictDatesState}
              setExpiryDate={setExpiryDate}
              afterThreeDays={afterThreeDays}
              breakLongTimeSlots={breakLongTimeSlots}
              slotData={slotData}
              allCoachesList={allCoachesList}
            />
          )}
          {request && (
            <SingleRequestModal request={request} setRequest={setRequest} />
          )}
          {requestDetailsForDeletingRequest && (
            <DeleteRequestModal
              requestDetailsForDeletingRequest={
                requestDetailsForDeletingRequest
              }
              setRequestDetailsForDeletingRequest={
                setRequestDetailsForDeletingRequest
              }
            />
          )}
        </>
      )}
    </main>
  );
};

export { RequestSessions };
