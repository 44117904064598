import { Input } from "antd";
import { Loader } from "components";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { login, setAlert } from "redux-management";
import { validateEmail } from "utils";

const Login = () => {
  const initialInput = {
    username: "",
    password: "",
  };
  const dispatch = useDispatch();
  const [loginDetails, setLoginDetails] = useState(initialInput);
  const navigate = useNavigate();
  const { username, password } = loginDetails;
  const { authStatus } = useSelector((state) => state.auth);
  const handleInput = (e) => {
    setLoginDetails((prev) => ({
      ...prev,
      [e.target.name]: e.target.value.trim(),
    }));
  };

  const loginHandler = ({
    event,
    validateEmail,
    loginDetails,
    dispatch,
    navigate,
    setAlert,
  }) => {
    event.preventDefault();
    const { username } = loginDetails;
    // if (!validatePassword(password)) {
    //   return dispatch(
    //     setAlert({
    //       message: "Please check your password",
    //       isAlert: true,
    //       status: "failure",
    //     })
    //   );
    // }
    if (!validateEmail(username)) {
      return dispatch(
        setAlert({
          message: "Please check your email",
          isAlert: true,
          status: "failure",
        })
      );
    }
    // if (authStatus === "rejected" && authError.response.status === 404) {
    //   return dispatch(
    //     setAlert({
    //       message: "Invalid login credentials, no user found!",
    //       isAlert: true,
    //       status: "failure",
    //     })
    //   );
    // }
    dispatch(login({ loginDetails, navigate, setAlert }));
  };

  const token = sessionStorage.getItem("token");

  return token ? (
    <Navigate to={"/coach-request-availability"} replace></Navigate>
  ) : authStatus === "pending" ? (
    <Loader />
  ) : (
    <main className="mt-12">
      <h2 className="font-medium text-2xl text-center ">SLOT SCHEDULER</h2>
      <form
        onSubmit={(event) =>
          loginHandler({
            event,
            validateEmail,
            loginDetails,
            dispatch,
            navigate,
            setAlert,
          })
        }
        className="w-96 flex flex-col gap-2 mx-auto"
      >
        <label htmlFor="username" className="block">
          Email: <span className="text-red-500">*</span>
        </label>
        <Input
          placeholder="Input email"
          required
          type="text"
          name="username"
          value={username}
          id="text"
          onChange={(e) => handleInput(e)}
        />
        <label htmlFor="password" className="block">
          Password: <span className="text-red-500">*</span>
        </label>
        <Input.Password
          placeholder="Input password"
          required
          type="password"
          name="password"
          id="password"
          value={password}
          onChange={(e) => handleInput(e)}
        />
        {/* <p
          className="text-right text-sm cursor-pointer "
          onClick={() => navigate("/forgot-password")}
        >
          <span className="hover:text-sky-500 hover:border-b-2 border-sky-500">
            Forgot password?
          </span>
        </p> */}
        <div className="mt-2 text-center">
          <button className="px-4 py-1 rounded bg-primary-ctc hover:bg-primary-ctc-hover text-white">
            Login
          </button>
        </div>
      </form>
    </main>
  );
};

export { Login };
