import { Button, DatePicker, Form, Input, InputNumber, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addEvent,
  getCoaches,
  setAlert,
  getEventsList,
  editEvent,
} from "redux-management";
const { Option } = Select;
const { RangePicker } = DatePicker;

const EventForm = ({
  setCreateEvent,
  editEventDetails,
  setEditEventDetails,
}) => {
  const [form] = Form.useForm();
  const { allCoachesList } = useSelector((state) => state.manageCoaches);
  const { batchesList } = useSelector((state) => state.batchView);
  const dispatch = useDispatch();
  const [status, setStatus] = useState({});
  console.log(batchesList);
  const validateEventName = (value) => {
    const eventName = value.trim();
    if (eventName === "") {
      setStatus((prev) => ({
        ...prev,
        eventName: {
          validateStatus: "error",
          errorMsg: "Event name should not be empty!",
        },
      }));
      return false;
    }
    setStatus((prev) => ({
      ...prev,
      eventName: {
        validateStatus: "success",
        errorMsg: null,
      },
    }));
    return true;
  };

  const validateNumber = (value) => {
    if (value === null) {
      setStatus((prev) => ({
        ...prev,
        numberOfSlots: {
          validateStatus: "error",
          errorMsg: "Number of slots should not be empty!",
        },
      }));
      return false;
    }
    setStatus((prev) => ({
      ...prev,
      numberOfSlots: {
        validateStatus: "success",
        errorMsg: null,
      },
    }));
    return true;
  };

  const disabledDate = (current) => {
    // Can not select days before today
    return current && current <= moment().subtract(1, "days");
  };

  // mapping all coaches to be selected
  let children = [];
  let allCoachIds = [];
  for (let i = 0; i < allCoachesList.length; i++) {
    if (allCoachesList[i].isActive) {
      allCoachIds.push(allCoachesList[i].id);
      children.push(
        <Option
          key={allCoachesList[i].id}
          value={allCoachesList[i].id}
        >{`${allCoachesList[i].first_name} ${allCoachesList[i].middle_name} ${allCoachesList[i].last_name}`}</Option>
      );
    }
  }

  let childrenTwo = [];
  for (let i = batchesList.length - 1; i >= 0; i--) {
    childrenTwo.push(
      <Option key={batchesList[i].batch} value={batchesList[i].batch}>
        {batchesList[i].batch}
      </Option>
    );
  }

  const addEventHandler = ({
    values,
    setAlert,
    form,
    validateEventName,
    dispatch,
    addEvent,
    setCreateEvent,
    getEventsList,
  }) => {
    const {
      eventName,
      startAndEndDate,
      expiryDate,
      coachIds,
      numberOfSlotsPerEvent,
      batch,
    } = values;
    let eventNameValidated = validateEventName(eventName);
    let NumberOfSlotsValidated = validateNumber(numberOfSlotsPerEvent);
    if (!eventNameValidated || !NumberOfSlotsValidated) {
      return dispatch(
        setAlert({
          message: "Resolve the errors before submitting the form",
          isAlert: true,
          status: "failure",
        })
      );
    }
    dispatch(
      addEvent({
        eventDetails: {
          name: eventName.trim(),
          start_date: startAndEndDate[0].format("YYYY-MM-DD"),
          end_date: startAndEndDate[1].format("YYYY-MM-DD"),
          expire_date: expiryDate.format("YYYY-MM-DD"),
          count: numberOfSlotsPerEvent,
          coach: coachIds,
          batch: batch,
        },
        form,
        setAlert,
        setCreateEvent,
        getEventsList,
      })
    );
  };

  const editEventHandler = ({
    values,
    setAlert,
    form,
    validateEventName,
    dispatch,
    editEvent,
    setCreateEvent,
    getEventsList,
    setEditEventDetails,
    editEventDetails,
  }) => {
    const {
      eventName,
      startAndEndDate,
      expiryDate,
      coachIds,
      numberOfSlotsPerEvent,
      batch,
    } = values;
    let eventNameValidated = validateEventName(eventName);
    if (!eventNameValidated) {
      return dispatch(
        setAlert({
          message: "Please enter all mandatory fields or resolve the errors",
          isAlert: true,
          status: "failure",
        })
      );
    }
    let event = {
      name: eventName.trim(),
      start_date: startAndEndDate[0].format("YYYY-MM-DD"),
      end_date: startAndEndDate[1].format("YYYY-MM-DD"),
      expire_date: expiryDate.format("YYYY-MM-DD"),
      count: numberOfSlotsPerEvent,
      coach: coachIds,
      batch: batch,
    };
    dispatch(
      editEvent({
        eventDetails: event,
        form,
        setAlert,
        setCreateEvent,
        setEditEventDetails,
        getEventsList,
        eventId: editEventDetails.id,
      })
    );
  };

  // get all coaches by api call
  useEffect(() => {
    dispatch(getCoaches());
  }, [dispatch]);

  useEffect(() => {
    if (editEventDetails) {
      const { name, start_date, end_date, expire_date, coach, count, batch } =
        editEventDetails;
      form.setFieldsValue({
        eventName: name,
        startAndEndDate: [
          moment(new Date(start_date)),
          moment(new Date(end_date)),
        ],
        expiryDate: moment(new Date(expire_date)),
        coachIds: coach,
        numberOfSlotsPerEvent: count,
        batch: batch,
      });
    }
  }, [editEventDetails, form]);

  return (
    <Form
      form={form}
      layout="vertical"
      className="w-[30rem] mt-3 mb-6 min-h-[100vh]"
      onFinish={
        editEventDetails
          ? (values) =>
              editEventHandler({
                values,
                setAlert,
                form,
                validateEventName,
                dispatch,
                editEvent,
                setCreateEvent,
                getEventsList,
                setEditEventDetails,
                editEventDetails,
              })
          : (values) =>
              addEventHandler({
                values,
                setAlert,
                form,
                validateEventName,
                dispatch,
                addEvent,
                setCreateEvent,
                getEventsList,
              })
      }
    >
      <Form.Item
        className="mb-2"
        name="eventName"
        rules={[
          {
            type: "string",
            required: true,
          },
        ]}
        validateStatus={status?.eventName?.validateStatus}
        help={status?.eventName?.errorMsg}
        label="Event Name :"
      >
        <Input
          placeholder="Enter event name"
          onChange={(event) => validateEventName(event.target.value)}
        />
      </Form.Item>
      <Form.Item
        name="numberOfSlotsPerEvent"
        className="mb-2"
        label="Minimum number of slots per event :"
        rules={[
          {
            required: true,
          },
          {
            type: "number",
            min: 1,
          },
        ]}
        validateStatus={status?.numberOfSlots?.validateStatus}
        help={status?.numberOfSlots?.errorMsg}
      >
        <InputNumber
          type="number"
          onKeyDown={(e) => {
            if (
              e.key === "e" ||
              e.key === "." ||
              e.key === "+" ||
              e.key === "-"
            ) {
              e.preventDefault();
            }
          }}
          placeholder="Enter number of slots"
          className="w-full"
          onChange={(value) => validateNumber(value)}
        />
      </Form.Item>
      <Form.Item
        className="mb-2"
        rules={[
          {
            required: true,
          },
        ]}
        name="startAndEndDate"
        label="Start and end dates :"
      >
        <RangePicker disabledDate={disabledDate} className="w-full" />
      </Form.Item>
      <Form.Item
        className="mb-2"
        rules={[
          {
            type: "date",
            required: true,
          },
        ]}
        name="expiryDate"
        label="Expiry date :"
      >
        <DatePicker disabledDate={disabledDate} className="w-full" />
      </Form.Item>
      <div className="relative">
        <Form.Item
          label="Coaches :"
          name="coachIds"
          rules={[
            {
              type: "array",
              required: true,
            },
          ]}
        >
          <Select
            mode="multiple"
            allowClear
            className="w-96 mt-2"
            placeholder="Select coaches"
            filterOption={(input, option) =>
              option.children
                .toLowerCase()
                .split(" ")
                .join("")
                .includes(input.toLowerCase().split(" ").join(""))
            }
          >
            {children}
          </Select>
        </Form.Item>
        <Button
          type="secondary"
          className="absolute top-0 right-0"
          onClick={() =>
            form.setFieldsValue({
              coachIds: allCoachIds,
            })
          }
        >
          Add all coaches
        </Button>
      </div>
      <Form.Item
        label="Batch :"
        name="batch"
        rules={[
          {
            type: "string",
            required: true,
          },
        ]}
      >
        <Select
          showSearch
          allowClear
          className="w-96 mt-2"
          placeholder="Select batches"
          filterOption={(input, option) =>
            option.children
              .toLowerCase()
              .split(" ")
              .join("")
              .includes(input.toLowerCase().split(" ").join(""))
          }
        >
          {childrenTwo}
        </Select>
      </Form.Item>
      <div className="flex gap-4 mt-5 justify-center">
        {editEventDetails ? (
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        ) : (
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        )}
        <Button
          type="secondary"
          onClick={() => {
            setCreateEvent(false);
            if (editEventDetails) {
              setEditEventDetails(null);
            }
          }}
        >
          Cancel
        </Button>
      </div>
    </Form>
  );
};

export { EventForm };
