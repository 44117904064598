import { configureStore } from "@reduxjs/toolkit";
import coachingSessionSlotBookingReducer from "./slices/coaching-session-slot-booking/coachingSessionSlotBookingSlice";
import manageCoursesReducer from "./slices/manage-courses/manageCoursesSlice";
import manageLearnersReducer from "./slices/manage-learners/manageLearnersSlice";
import manageCoachesReducer from "./slices/manage-coaches/manageCoachesSlice";
import viewCoachSlotsReducer from "./slices/view-coach-slots/viewCoachSlotsSlice";
import viewLearnerSlotsReducer from "./slices/view-learner-slots/viewLearnerSlotsSlice";
import manageFacultiesReducer from "./slices/manage-faculties/manageFacultiesSlice";
import authReducer from "./slices/authentication/authSlice";
import alertReducer from "./slices/alert/alertSlice";
import defineCoachingSessionsReducer from "./slices/request-sessions/requestSessionsSlice";
import editCoachConfirmedSlotsReducer from "./slices/edit-coach-confirmed-slots/editCoachConfirmedSlotsSlice";
import manageEventsReducer from "./slices/manage-events/manageEventsSlice";
import confirmedSessionsReducer from "./slices/confirmed-sessions/confirmedSessionsSlice";
import batchViewReducer from "./slices/batch-view/batchViewSlice";

export const store = configureStore({
  reducer: {
    coachingSessionSlotBooking: coachingSessionSlotBookingReducer,
    manageCourses: manageCoursesReducer,
    manageLearners: manageLearnersReducer,
    manageCoaches: manageCoachesReducer,
    viewCoachSlots: viewCoachSlotsReducer,
    viewLearnerSlots: viewLearnerSlotsReducer,
    manageFaculties: manageFacultiesReducer,
    auth: authReducer,
    alert: alertReducer,
    defineCoachingSessions: defineCoachingSessionsReducer,
    editCoachConfirmedSlots: editCoachConfirmedSlotsReducer,
    manageEvents: manageEventsReducer,
    confirmedSessions: confirmedSessionsReducer,
    batchView: batchViewReducer,
  },
});
