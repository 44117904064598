import { Button, Form, Input } from "antd";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getConfirmedSlotsByEventId,
  setAlert,
  deleteConfirmedSession,
} from "redux-management";
import { Loader } from "components";
const { TextArea } = Input;

const DeleteCoachingSessionSlotModal = ({
  deleteSlotDetails,
  setDeleteSlotDetails,
}) => {
  const { learnerConfirmedSlotId, coachConfirmedSlotId } = deleteSlotDetails;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { expandedRowKeys, confirmedSessionsStatus } = useSelector(
    (state) => state.confirmedSessions
  );

  // Stop scroll of background page
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return ReactDOM.createPortal(
    <>
      <div
        className="fixed inset-0 z-modal-bg bg-slate-300 opacity-70"
        onClick={() => setDeleteSlotDetails(null)}
      />
      <section className="py-1 flex flex-col gap-2 px-8 z-modal w-11/12 sm:w-9/12 lg:w-2/3 xl:w-1/3 border border-zinc-400 rounded fixed top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 bg-white">
        {confirmedSessionsStatus === "pending" ? (
          <Loader />
        ) : (
          <>
            <h3 className="text-xl my-2 text-center font-medium">
              Delete Slot
            </h3>
            <Form
              form={form}
              layout="vertical"
              className="mb-6"
              onFinish={(formData) => {
                dispatch(
                  deleteConfirmedSession({
                    learnerConfirmedSlotId,
                    coachConfirmedSlotId,
                    setAlert,
                    getConfirmedSlotsByEventId,
                    expandedRowKeys,
                    formData,
                    setDeleteSlotDetails,
                    form,
                  })
                );
              }}
            >
              <Form.Item
                className="mb-2"
                name="adminName"
                rules={[
                  {
                    type: "string",
                    required: true,
                  },
                ]}
                // validateStatus={status?.adminName?.validateStatus}
                // help={status?.adminName?.errorMsg}
                label="Admin name :"
              >
                <Input placeholder="Enter admin name" />
              </Form.Item>
              <Form.Item
                className="mb-2"
                name="requestedPerson"
                rules={[
                  {
                    type: "string",
                    required: true,
                  },
                ]}
                label="Requested by :"
              >
                <Input placeholder="Enter requested person name" />
              </Form.Item>
              <Form.Item
                className="mb-2"
                name="reason"
                rules={[
                  {
                    type: "string",
                    required: true,
                  },
                ]}
                label="Reason :"
              >
                <TextArea rows={3} placeholder="Enter reason" />
              </Form.Item>
              <div className="mt-6 flex justify-end">
                <Button htmlType="submit" className="mr-4" type="secondary">
                  Delete
                </Button>
                <Button
                  type="primary"
                  onClick={() => setDeleteSlotDetails(null)}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          </>
        )}
      </section>
    </>,
    document.getElementById("portal")
  );
};

export { DeleteCoachingSessionSlotModal };
