import { format } from "date-fns";

export const getTimeSlotsWithStep = (step, numOfTimeSlots, initialTimeSlot) => {
  const todayDate = format(new Date(), "yyyy-MM-dd");
  let timestamp = new Date(`${todayDate}T${initialTimeSlot}:00`).getTime();
  let result = [initialTimeSlot];
  for (let i = 0; i < numOfTimeSlots; i++) {
    result.push(
      new Date(timestamp + step * 60000).toLocaleTimeString([], {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
      })
    );
    timestamp = timestamp + step * 60000;
  }
  return result;
};
