import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert, uploadParticipants, getBatchesList } from "redux-management";
import ReactDOM from "react-dom";
import * as xlsx from "xlsx";
import { AiOutlineClose } from "assets/icons/icons";
import { Button } from "antd";

const ReadFile = ({ apiCall, setOpenTable }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const readFile = (event, apiCall) => {
    event.preventDefault();
    if (event.target.files) {
      const reader = new FileReader();
      const errorFile = (listOfProperties, participant) => {
        for (let prop of listOfProperties) {
          if (!(prop in participant)) {
            dispatch(
              setAlert({
                message: `${prop} is empty!`,
                isAlert: true,
                status: "failure",
              })
            );
            return false;
          }
        }
        return true;
      };

      reader.onload = (event) => {
        try {
          const data = event.target.result;
          const workbook = xlsx.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = xlsx.utils.sheet_to_json(worksheet);
          for (let i = 0; i < json.length; i++) {
            const participant = json[i];
            if (participant.last_name === undefined) {
              participant.last_name = "";
            }
            if (participant.course === undefined) {
              participant.course = "";
            }
            if (participant.phone === undefined) {
              participant.phone = "";
            }
            participant.batch = participant.batch.trim();
            participant.email = participant.email?.toLowerCase().trim();
            let mandatoryProperties = ["first_name", "email", "batch"];
            const isErrorFile = errorFile(mandatoryProperties, participant);
            if (isErrorFile) {
              setData(json);
            }
          }
        } catch (err) {
          dispatch(
            setAlert({
              message: "Uploading file failed!",
              isAlert: true,
              status: "failure",
            })
          );
        }
      };
      reader.readAsArrayBuffer(event.target.files[0]);
    }
  };

  return ReactDOM.createPortal(
    <>
      <div className="fixed inset-0 z-modal-bg bg-slate-300 opacity-70" />
      <section className="py-4 px-8 z-modal w-11/12 sm:w-9/12 lg:w-2/3 xl:w-1/3 border border-zinc-400 rounded fixed top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 bg-white">
        <AiOutlineClose
          className="text-2xl absolute top-2 right-2 cursor-pointer"
          onClick={() => setOpenTable(false)}
        />
        <form>
          <label htmlFor="upload">Upload File:</label>
          <input
            className="block mt-2 cursor-pointer"
            type="file"
            name="upload"
            id="upload"
            onChange={(event) => readFile(event, apiCall)}
          />
        </form>
        <Button
          disabled={!data}
          type="primary"
          className="mt-4 block mx-auto"
          onClick={() => {
            dispatch(
              uploadParticipants({
                learnersData: data,
                setAlert,
                getBatchesList,
              })
            );
            setOpenTable(false);
          }}
        >
          Upload
        </Button>
      </section>
    </>,
    document.getElementById("portal")
  );
};

export { ReadFile };
