import { Table } from "antd";
import { useEffect } from "react";
import { AiOutlineClose } from "assets/icons/icons";
import { useDispatch, useSelector } from "react-redux";
import { getConfirmedSlotsByEventId } from "redux-management";
import { getAvailableSlotsByEventId } from "redux-management/slices/manage-events/manageEventsService";

const EventModal = ({ rowData, setEventDetails, eventsList }) => {
  const { confirmedSlotsByEventId, availableSlotsByEventId } = useSelector(
    (state) => state.manageEvents
  );
  const { allCoachesList } = useSelector((state) => state.manageCoaches);
  const dispatch = useDispatch();

  const getCoachesSlots = (slots, coachId, type, event) => {
    const res = [];
    slots.forEach((slot) => {
      if (type === "AVAILABLE") {
        if (
          new Date(slot.date) >= new Date(event.start_date) &&
          new Date(slot.date) <= new Date(event.end_date)
        ) {
          if (+slot.coach_id === coachId) {
            res.push(slot);
          }
        }
      } else {
        if (
          new Date(slot.slot.date) >= new Date(event.start_date) &&
          new Date(slot.slot.date) <= new Date(event.end_date)
        ) {
          if (+slot.slot.coach_id === coachId) {
            res.push(slot);
          }
        }
      }
    });
    return res;
  };

  const getSlots = (slots, type, event) => {
    const res = [];
    slots.forEach((slot) => {
      if (type === "AVAILABLE") {
        if (
          new Date(slot.date) >= new Date(event.start_date) &&
          new Date(slot.date) <= new Date(event.end_date)
        ) {
          res.push(slot);
        }
      } else {
        if (
          new Date(slot.slot.date) >= new Date(event.start_date) &&
          new Date(slot.slot.date) <= new Date(event.end_date)
        ) {
          res.push(slot);
        }
      }
    });
    return res;
  };

  const coachList = rowData?.coach.reduce((acc, curr) => {
    const coachName = allCoachesList.find((coach) => curr === coach.id);
    return [
      ...acc,
      {
        coachName: `${coachName.first_name} ${coachName.middle_name} ${coachName.last_name}`,
        confirmedSlots: getCoachesSlots(
          confirmedSlotsByEventId,
          curr,
          "CONFIRMED",
          rowData
        ).length,
        availableSlots: getCoachesSlots(
          availableSlotsByEventId,
          curr,
          "AVAILABLE",
          rowData
        ).length,
      },
    ];
  }, []);

  const columns = [
    {
      title: "Coach Names",
      dataIndex: "coachName",
      key: "coachName",
      width: 180,
    },
    {
      title: "Confirmed Slots",
      dataIndex: "confirmedSlots",
      key: "confirmedSlots",
      width: 150,
    },
    {
      title: "Available Slots",
      dataIndex: "availableSlots",
      key: "availableSlots",
      width: 150,
    },
  ];

  useEffect(() => {
    if (rowData) {
      dispatch(getConfirmedSlotsByEventId(rowData.id));
      dispatch(
        getAvailableSlotsByEventId(
          eventsList.find((item) => item.id === rowData.id)._id
        )
      );
    }
  }, [dispatch, rowData, eventsList]);

  // Stop scroll of background page
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <>
      <div
        className="fixed inset-0 z-modal-bg bg-slate-300 opacity-70"
        onClick={() => setEventDetails(null)}
      />
      <section className="py-6 px-12 z-modal w-11/12 sm:w-9/12 lg:w-2/3 border border-zinc-400 rounded fixed top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 bg-white">
        <h3 className="text-center text-xl font-medium">Event Details</h3>
        <p>
          <span className="font-semibold">Slots required: </span>
          {rowData.min_count}
        </p>
        <p>
          <span className="font-semibold">Slots confirmed: </span>
          {getSlots(confirmedSlotsByEventId, "CONFIRMED", rowData).length}
        </p>
        <p>
          <span className="font-semibold">Slots open: </span>
          {getSlots(availableSlotsByEventId, "AVAILABLE", rowData).length}
        </p>
        <Table
          className="my-2 border-2"
          columns={columns}
          dataSource={coachList}
          pagination={false}
          scroll={{
            x: 330,
            y: 200,
          }}
        />
        <AiOutlineClose
          className="text-2xl absolute top-2 right-2 cursor-pointer"
          onClick={() => setEventDetails(null)}
        />
      </section>
    </>
  );
};

export { EventModal };
