import React from "react";
import LoadingImg from "assets/images/loading-gif.gif";

const Loader = () => {
  return (
    <img
      className="w-[22rem] mx-auto block mt-8"
      src={LoadingImg}
      alt="Loading..."
    />
  );
};

export { Loader };
