import { Table } from "antd";
import axios from "axios";
import { Loader } from "components";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

export const LearnersList = ({ batch }) => {
  const [fetchingLearners, setFetchingLearners] = useState({
    error: false,
    loading: true,
    data: [],
  });

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/get-learner-by-batch/${batch}`
        );
        setFetchingLearners({
          loading: false,
          error: false,
          data: response.data.data,
        });
      } catch (err) {
        setFetchingLearners({
          error: true,
          loading: false,
          data: [],
        });
      }
    })();
  }, [setFetchingLearners, batch]);

  const learnersDataForTable = fetchingLearners.data.map((learner) => ({
    name: learner.first_name + " " + learner.last_name,
    email: learner.email,
    phone: learner.phone,
  }));

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: 200,
      render: (phone) => (phone ? phone : "N/A"),
    },
  ];

  if (fetchingLearners.loading === false && fetchingLearners.error === false) {
    return (
      <>
        <p className="pl-8 font-semibold">
          Total Learners : {fetchingLearners.data.length}
        </p>
        <Table
          className="pl-4"
          columns={columns}
          dataSource={learnersDataForTable}
          scroll={{
            x: 600,
            y: 600,
          }}
        />
      </>
    );
  }

  if (fetchingLearners.loading) {
    return <Loader />;
  }

  if (fetchingLearners.error && fetchingLearners.loading === false) {
    return <p>Failed to load learners details</p>;
  }
};
