import { Collapse } from "antd";
import React from "react";

const items = [
  {
    key: "1",
    label: "Request coach availability",
    children:
      "You can here request availability to multiple coaches on multiple dates.",
    video: (
      <iframe
        src="https://www.veed.io/embed/56a6b597-573b-4a67-a31c-3d0ef3439d24"
        width="744"
        height="504"
        frameborder="0"
        title="Screen Recording - June 8, 2023"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
      ></iframe>
    ),
  },
  {
    key: "5",
    label: "Edit availability",
    children:
      "As Admin you can only delete the slots provided by the coach. Admin cannot add new slots.",
  },
  {
    key: "6",
    label: "Manage events",
    children:
      "An event needs to be created in order for the participants to be able to book their coaching slots.",
  },
  {
    key: "2",
    label: "Create event",
    children:
      "An event should be linked to a batch and to a list of coaches. Hence make sure you have created the batch and coaches’ profile before creating an event. You can create an event under the participant's manage events section.",
  },
  {
    key: "3",
    label: "Add batch",
    children:
      "Use the sample excel template provided under Batch view in order to create and upload the batch data",
  },
  {
    key: "4",
    label: "Add new coach",
    children:
      "Have the coach details (Name, email id, phone number) handy before adding a coach to the platform.",
  },
];

const { Panel } = Collapse;

export const Help = () => {
  return (
    <main className="xl:col-start-2 pt-4 xl:col-end-7 w-[98%] mx-auto">
      <Collapse accordion={true}>
        {items.map((item) => (
          <Panel header={item.label} key={item.key}>
            {item.video}
            {item.children}
          </Panel>
        ))}
      </Collapse>
    </main>
  );
};
