import { Spin } from "antd";
import React from "react";

export const LoadingAndError = ({ loading, error, children, data }) => {
  return (
    <>
      {loading && <Spin />}
      {error && <p className="text-gray-400">Failed to load details</p>}
      {!loading && !error && data && <>{children}</>}
    </>
  );
};
