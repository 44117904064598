import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert, Button, Input, Modal, Tooltip } from "antd";
import ContentEditable from "react-contenteditable";
import axios from "axios";
import {  ArrowLeftOutlined } from "@ant-design/icons";
import { CopyOutlined } from "@ant-design/icons";
import { useGetApi } from "hooks/useGetApi";

const Templates = ({}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { templatePart, templateId, title } = location.state || {};
  const [htmlContent, setHtmlContent] = useState(templatePart);
  const [templateTitle, setTemplateTitle] = useState(title);
  const [copiedText, setCopiedText] = useState(null);
  const { data, isLoading, error } = useGetApi(
    `${process.env.REACT_APP_BASE_URL}/pending-scheduled-mails/${templateId}/`
  );

  const [isModalOpenSaveExit, setIsModalOpenSaveExit] = useState(false);
  const showModalSaveExit = () => {
    setIsModalOpenSaveExit(true);
  };
  const handleOkSaveExit = () => {
    handleSaveTemplate();
    setIsModalOpenSaveExit(false);
  };

  const handleCancelSaveExit = () => {
    setIsModalOpenSaveExit(false);
  };

  const handleContentChange = (event) => {
    setHtmlContent(event.target.value);
  };

  const handleSaveTemplate = async () => {
    try {
      if (!templateTitle) {
        window.alert("Please enter a title.");
        return;
      }

      if (templateId) {
        const response = await axios.put(
          `${process.env.REACT_APP_BASE_URL}/edit_email_template/${templateId}/`,
          {
            title: templateTitle,
            templatedata: htmlContent,
          }
        );
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/save_template/`,
          {
            title: templateTitle,
            templatedata: htmlContent,
          }
        );
      }
      navigate("/templates", {
        state: { title: templateTitle, templatePart: htmlContent },
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCodeClick = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    setCopiedText(text);
    setTimeout(() => setCopiedText(null), 3000); // Clear copied text after 3 seconds
  };

  return (
    <main className="xl:col-start-2 pt-4 xl:col-end-7 w-[98%] mx-auto">
      <div className="flex border-b p-4">
        <div className="text-lg flex gap-4">
          <div>
            <ArrowLeftOutlined onClick={() => navigate(-1)} />
          </div>
          <div className="p-1">Create Email Template</div>
        </div>

        <div className="flex gap-2 ml-auto">
          <Tooltip title={data?.exists ? "Scheduled mails already exists" : ""}>
            <Button
              disabled={data?.exists}
              loading={isLoading}
              className="rounded-lg"
              type="primary"
              onClick={showModalSaveExit}
            >
              Save and Exit
            </Button>
          </Tooltip>
        </div>
      </div>
      <div className="mb-[1rem] ml-[2rem] mt-[1rem]">
        {data?.exists && (
          <Alert
            className="mb-4"
            type="error"
            message="This template can't be edited, scheduled emails already exists."
          ></Alert>
        )}
        <div className="max-w-full mx-auto bg-white p-6 rounded shadow mb-4">
          <h3 className="text-2xl  mb-4">Adding Variables to Content</h3>
          <p className="mb-2">
            Make sure there are no spaces between the curly braces and the
            variable name.
          </p>
          <ul className="list-disk pl-6 mt-2">
            <li onClick={() => handleCodeClick("{{learnerName}}")}>
              If you want to include the Learner name, use{" "}
              <code className="bg-gray-200 p-1 rounded cursor-pointer">
                &#123;&#123;learnerName&#125;&#125;
              </code>{" "}
              <CopyOutlined className="!text-[16px] cursor-pointer" />
              &nbsp; in your text.
            </li>
          </ul>
          <div className="mt-4 h-5">
            {copiedText && (
              <p className="text-green-600 ">Copied: {copiedText}</p>
            )}
          </div>
        </div>
      </div>{" "}
      <div className="flex mt-[1rem] gap-2 px-14">
        <div>
          <span className="text-lg">Title</span>
          <span className="text-red-500">*</span>
        </div>
        <div>
          <Input
            className="w-[full]"
            type="text"
            placeholder="Enter template title"
            value={templateTitle}
            onChange={(event) => setTemplateTitle(event.target.value)}
            maxLength={100}
          />
        </div>
      </div>
      <div className="flex gap-4 mt-[1rem] px-14">
        <div>
          <div className="text-2xl text-center mb-[1rem] border-gray-500 bg-gray-200 p-2">
            HTML Code{" "}
          </div>
          <div className="border p-2">
            <div>
              <textarea
                value={htmlContent}
                onChange={handleContentChange}
                rows={50}
                cols={90}
                style={{ border: "none", outline: "none" }}
                placeholder="Paste HTML code here..."
              />
            </div>
          </div>
        </div>
        <div>
          <div className="text-2xl w-[39rem] text-center mb-[1rem] border-gray-500 bg-gray-200 p-2">
            Preview Code{" "}
          </div>
          <div className="border p-2">
            <ContentEditable
              className="border-gray-300 h-[50rem]"
              html={htmlContent}
              onChange={(event) => setHtmlContent(event.target.value)}
            />
          </div>
        </div>
      </div>
      <Modal
        title="Confirm Save"
        footer={false}
        visible={isModalOpenSaveExit}
        onOk={handleOkSaveExit}
        onCancel={handleCancelSaveExit}
      >
        <div className="flex flex-col gap-4">
          <div>
            This will update the template. Are you sure you want to save the
            current template?
          </div>
          <div className="ml-auto mt-[1rem]">
            <Button
              onClick={handleCancelSaveExit}
              className="mr-[.5rem]"
              type="primary"
            >
              Cancel
            </Button>
            <Button onClick={handleOkSaveExit} type="primary">
              Save
            </Button>
          </div>
        </div>
      </Modal>
    </main>
  );
};

export { Templates };
