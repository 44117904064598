import React, { useState } from "react";
import { MdArrowDropDown, MdArrowRight } from "react-icons/md";
import { NavLink, useLocation } from "react-router-dom";

const MobileMenu = ({ isHamburger, setIsHamburger }) => {
  const [section, setSection] = useState([]);
  const { pathname } = useLocation();

  const activePage =
    "p-2 block hover:text-white text-aside-bar-text transition-none rounded bg-aside-bar-bg " +
    (pathname.startsWith("/coach") || pathname.startsWith("/participant")
      ? "bg-[#0363A3]/10 text-black border-l-4 hover:text-black border-[#0363A3] rounded-none rounded-r-lg	"
      : "bg-aside-bar-bg");
  const inActivePage =
    "p-2 transition-0 block hover:text-slate-700 transition-none text-slate-700 rounded ";

  if (!isHamburger) {
    return null;
  }

  return (
    <>
      <div
        className="fixed inset-0 z-mobile-menu bg-slate-300 opacity-70"
        onClick={() => setIsHamburger(!isHamburger)}
      />
      <section className="w-64 border border-black xl:hidden bg-white fixed top-16 right-0 z-mobile-menu">
        <div
          className="w-56 my-4 flex flex-col gap-4 mx-auto"
          onClick={() => setIsHamburger(!isHamburger)}
        >
          {/* <NavLink
          to="/coaching-session-slot-booking"
          className={({ isActive }) => (isActive ? activePage : inActivePage)}
        >
          Coaching Session Slot Booking
        </NavLink>
        <NavLink
          to="/view-coach-slots"
          className={({ isActive }) => (isActive ? activePage : inActivePage)}
        >
          View Coach Slots
        </NavLink>
        <NavLink
          to="/manage-learners"
          className={({ isActive }) => (isActive ? activePage : inActivePage)}
        >
          Manage Learners
        </NavLink>
        <NavLink
          to="/manage-courses"
          className={({ isActive }) => (isActive ? activePage : inActivePage)}
        >
          Manage Courses
        </NavLink>
        <NavLink
          to="/reports"
          className={({ isActive }) => (isActive ? activePage : inActivePage)}
        >
          Reports
        </NavLink>
        <NavLink
          to="/view-learner-slots"
          className={({ isActive }) => (isActive ? activePage : inActivePage)}
        >
          View Learner Slots
        </NavLink> */}
          <div className="">
            <span
              className={
                (section.includes("COACH")
                  ? "hover:text-white text-aside-bar-text rounded bg-aside-bar-bg p-2 block"
                  : "") +
                " p-2 cursor-pointer flex items-center gap-1 border-l-4 border-transparent "
              }
              onClick={(e) => {
                e.stopPropagation();
                setSection((prev) =>
                  prev.includes("COACH")
                    ? prev.filter((section) => section !== "COACH")
                    : [...prev, "COACH"]
                );
              }}
            >
              Coach{" "}
              {section.includes("COACH") ? (
                <MdArrowDropDown className="text-lg" />
              ) : (
                <MdArrowRight className="text-lg" />
              )}
            </span>
            {section.includes("COACH") && (
              <div className="pl-4 pt-2">
                <NavLink
                  to="/coach-profiles"
                  className={({ isActive }) =>
                    isActive ? activePage : inActivePage
                  }
                >
                  Profiles
                </NavLink>
                <NavLink
                  to="/coach-request-availability"
                  className={({ isActive }) =>
                    isActive ? activePage : inActivePage
                  }
                >
                  Request Availability
                </NavLink>

                <NavLink
                  to="/coach-edit-availability"
                  className={({ isActive }) =>
                    isActive ? activePage : inActivePage
                  }
                >
                  Edit Availability
                </NavLink>
              </div>
            )}
          </div>

          <div className="">
            <span
              className={
                (section.includes("PARTICIPANT")
                  ? "hover:text-white text-aside-bar-text rounded bg-aside-bar-bg p-2 block"
                  : "") +
                " p-2 cursor-pointer flex items-center gap-1 border-l-4 border-transparent "
              }
              onClick={(e) => {
                e.stopPropagation();
                setSection((prev) =>
                  prev.includes("PARTICIPANT")
                    ? prev.filter((section) => section !== "PARTICIPANT")
                    : [...prev, "PARTICIPANT"]
                );
              }}
            >
              Participant{" "}
              {section.includes("PARTICIPANT") ? (
                <MdArrowDropDown className="text-lg" />
              ) : (
                <MdArrowRight className="text-lg" />
              )}
            </span>
            {section.includes("PARTICIPANT") && (
              <div className="pl-4 pt-2">
                <NavLink
                  to="/participant-batch-view"
                  className={({ isActive }) =>
                    isActive ? activePage : inActivePage
                  }
                >
                  Batch view
                </NavLink>

                <NavLink
                  to="/participant-manage-events"
                  className={({ isActive }) =>
                    isActive ? activePage : inActivePage
                  }
                >
                  Manage events
                </NavLink>
              </div>
            )}
          </div>

          <NavLink
            to="/confirmed-sessions"
            className={({ isActive }) => (isActive ? activePage : inActivePage)}
          >
            Confirmed sessions
          </NavLink>
          <NavLink
            to="/help"
            className={({ isActive }) => (isActive ? activePage : inActivePage)}
          >
            Help
          </NavLink>

          {/* <NavLink
          to="/manage-faculties"
          className={({ isActive }) => (isActive ? activePage : inActivePage)}
        >
          Manage Faculties
        </NavLink> */}
        </div>
      </section>
    </>
  );
};

export { MobileMenu };
