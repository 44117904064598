import React, { useState } from "react";
import { Calendar } from "react-multi-date-picker";
import { Button, DatePicker, Input, Select } from "antd";
import { DayWrapper } from "components";
import { AiOutlineClose } from "assets/icons/icons";
import {
  setCoachingSessionDates,
  setAlert,
  getAdminRequests,
} from "redux-management";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { getTimeSlotsWithStep } from "utils";
const { Option } = Select;

const RequestSessionForm = ({
  dispatchSlotData,
  slotsConflictDatesState,
  expiryDate,
  setValues,
  setMakeRequest,
  scrollRef,
  values,
  setSlotsConflictDatesState,
  setExpiryDate,
  afterThreeDays,
  breakLongTimeSlots,
  slotData,
  allCoachesList,
}) => {
  const [addDates, setAddDates] = useState(false);
  const [requestName, setRequestName] = useState("");
  const [coachIds, setCoachIds] = useState([]);
  const { statusArrayForTimePicker } = useSelector(
    (state) => state.defineCoachingSessions
  );
  const dispatch = useDispatch();

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  };

  const checkingAllFieldsAreNotEmpty = (slotData) => {
    let result = true;
    for (let date in slotData) {
      if (!result) {
        return null;
      }
      for (let obj of slotData[date]) {
        if (obj.startTime !== "" && obj.endTime !== "") {
          continue;
        }
        result = false;
        return null;
      }
    }
    return true;
  };

  // set selected coach id's in an array
  const selectCoachHandler = (value) => {
    setCoachIds([...value]);
  };

  const onChangeExpiryDate = (_, dateString) => {
    setExpiryDate(dateString);
  };

  // mapping all coaches to be selected
  let children = [];
  let allCoachIds = [];
  for (let i = 0; i < allCoachesList.length; i++) {
    if (allCoachesList[i].isActive) {
      allCoachIds.push(`${allCoachesList[i].id}`);
      children.push(
        <Option
          key={allCoachesList[i].id}
        >{`${allCoachesList[i].first_name} ${allCoachesList[i].middle_name} ${allCoachesList[i].last_name}`}</Option>
      );
    }
  }

  // getting all dates into an array
  const allDatesSelected = values?.reduce((acc, curr) => {
    acc.push(format(new Date(curr), "dd-MM-yyyy"));
    return acc;
  }, []);

  // sending the request of coaching session dates and time slots
  const sendRequestHandler = ({
    breakLongTimeSlots,
    setAddDates,
    addDates,
    dispatchSlotData,
    setValues,
    setCoachingSessionDates,
    dispatch,
    requestName,
    setRequestName,
    slotData,
    checkingAllFieldsAreNotEmpty,
    setAlert,
    expiryDate,
    statusArrayForTimePicker,
    getAdminRequests,
    slotsConflictDatesState,
    setCoachIds,
    setMakeRequest,
  }) => {
    const slots = breakLongTimeSlots(slotData);
    if (Object.values(slotsConflictDatesState).includes(true)) {
      return dispatch(
        setAlert({
          message: "slots conflicting are not rectified yet!",
          isAlert: true,
          status: "failure",
        })
      );
    }

    if (
      requestName &&
      coachIds.length > 0 &&
      statusArrayForTimePicker === 0 &&
      expiryDate &&
      checkingAllFieldsAreNotEmpty(slotData)
    ) {
      dispatch(
        setCoachingSessionDates({
          requestName,
          coachIds,
          slots,
          setAlert,
          expiryDate,
          getAdminRequests,
        })
      );
      setAddDates(!addDates);
      dispatchSlotData({ type: "RESET_SLOTS" });
      setValues();
      setCoachIds([]);
      setRequestName("");
      setMakeRequest(false);
    } else {
      dispatch(
        setAlert({
          message:
            "Please enter all fields and check any conflicting slots before confirm and save",
          isAlert: true,
          status: "failure",
        })
      );
    }
  };
  let allTimeSlots = getTimeSlotsWithStep(30, 24, "09:00");

  return (
    <>
      <div ref={scrollRef} className="h-1"></div>
      <section className="mt-2 min-h-[100vh]">
        <div className="flex flex-wrap gap-2 my-4 items-center justify-between">
          <h3 className="text-xl">Select coaching session dates:</h3>
          <AiOutlineClose
            className="text-lg cursor-pointer mr-6"
            onClick={() => {
              setMakeRequest(false);
              setAddDates(!addDates);
              dispatchSlotData({ type: "RESET_SLOTS" });
              setValues();
              setCoachIds([]);
              setRequestName("");
            }}
          />
        </div>
        <div className="flex flex-col mb-8 lg:flex-row gap-12">
          <section className="flex flex-col gap-4">
            <Calendar
              multiple
              value={values}
              minDate={new Date()}
              onChange={(date) => {
                let newDate = date.sort((a, b) => {
                  let newA = new Date(a).getTime();
                  let newB = new Date(b).getTime();
                  if (newA < newB) {
                    return -1;
                  }
                  if (newA > newB) {
                    return 1;
                  }
                  // names must be equal
                  return 0;
                });
                setValues(newDate);
                setAddDates(true);
                dispatchSlotData({ type: "ADD_SLOTS" });
                date?.length > 0 &&
                  setSlotsConflictDatesState((prev) => {
                    return {
                      ...prev,
                      [format(
                        new Date(date[date.length - 1]),
                        "dd-MM-yyyy"
                      )]: false,
                    };
                  });
              }}
            />
          </section>
          {addDates && values?.length > 0 ? (
            <section className="mt-6 ml-2 flex flex-col flex-wrap gap-3">
              <h3 className="text-lg">
                Request name: <span className="text-red-500">*</span>
              </h3>
              <Input
                placeholder="Enter request name"
                className="w-96 lg:w-full"
                onChange={(event) => {
                  setRequestName(event.target.value);
                }}
              />
              <h3 className="text-lg">
                Request expiry date: <span className="text-red-500">*</span>
              </h3>
              <DatePicker
                disabledDate={disabledDate}
                className="w-96 lg:w-full"
                defaultValue={afterThreeDays}
                onChange={onChangeExpiryDate}
              />
              <div className="flex flex-wrap gap-2 justify-between">
                <h3 className="text-lg">
                  Select coaches to send the request:{" "}
                  <span className="text-red-500">*</span>
                </h3>
                <Button
                  type="secondary"
                  onClick={() => setCoachIds(allCoachIds)}
                >
                  Add all coaches
                </Button>
              </div>
              <Select
                mode="multiple"
                allowClear
                className="w-96 lg:w-full"
                placeholder="Please select coaches"
                value={coachIds}
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .split(" ")
                    .join("")
                    .includes(input.toLowerCase().split(" ").join(""))
                }
                onChange={selectCoachHandler}
              >
                {children}
              </Select>
              <h3 className="text-lg">
                Select session time for each date:{" "}
                <span className="text-red-500">*</span>
              </h3>
              <div className="flex flex-col rounded-lg mb-2">
                {allDatesSelected.map((day) => (
                  <DayWrapper
                    key={day}
                    day={day}
                    slotData={slotData}
                    dispatchSlotData={dispatchSlotData}
                    allTimeSlots={allTimeSlots}
                  />
                ))}
              </div>
              <Button
                type="primary"
                size="default"
                className="block w-fit bg-sky-500 font-semibold px-2 py-1 rounded mx-auto my-4"
                onClick={() =>
                  sendRequestHandler({
                    breakLongTimeSlots,
                    setAddDates,
                    addDates,
                    dispatchSlotData,
                    setValues,
                    setCoachingSessionDates,
                    dispatch,
                    requestName,
                    setRequestName,
                    slotData,
                    checkingAllFieldsAreNotEmpty,
                    setAlert,
                    expiryDate,
                    statusArrayForTimePicker,
                    getAdminRequests,
                    slotsConflictDatesState,
                    setCoachIds,
                    setMakeRequest,
                  })
                }
              >
                Confirm and Save
              </Button>
            </section>
          ) : (
            <section className="flex items-center">
              <p className="text-2xl">
                Select coaching session dates from the calender
              </p>
            </section>
          )}
        </div>
      </section>
    </>
  );
};

export { RequestSessionForm };
