import { Button, Modal, Select, Table, Spin } from "antd";
import { ReadFile } from "components";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBatchesList } from "redux-management";
import { DUMMY_LEARNERS } from "utils/constants";
import { BatchSampleExcel } from "./BatchSampleExcel";
import { LearnersList } from "./LearnersList";
import axios from "axios";
import { useGetApi } from "../../hooks/useGetApi";

const BatchView = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openTable, setOpenTable] = useState(false);
  const [expandedRow, setExpandedRow] = useState(undefined);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [apiResponse, setApiResponse] = useState([]);
  const [loading, setLoading] = useState(true);
  const {
    isLoading: participantDataLoading,
    error: participantDataError,
    data: participantData,
    getData: getPariticipantData,
  } = useGetApi(
    `${process.env.REACT_APP_BASE_URL}/get_participants_by_course/${selectedCourse}`
  );

  const dispatch = useDispatch();
  const { batchesList } = useSelector((state) => state.batchView);

  const columns = [
    {
      title: "Batch",
      dataIndex: "batch",
      key: "batch",
    },
  ];

  useEffect(() => {
    dispatch(getBatchesList());
  }, [dispatch]);

  const expandedRowRender = (record) => <LearnersList batch={record.batch} />;
  const batchesListWithKeys = batchesList.map((batch) => ({
    ...batch,
    key: batch.batch,
  }));

  const importFromThinkific = async () => {
    try {
      setOpenModal(true); // Open the Modal immediately upon clicking the button
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/get_courses_from_thinkific/`
      );
      if (response.status === 200) {
        setApiResponse(response.data);
        // setOpenModal(true);
      } else {
        console.error("API request failed with status:", response.status);
      }
    } catch (error) {
      console.error("API request error:", error);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (whether it succeeds or fails)
    }
  };

  useEffect(() => {
    if (selectedCourse) {
      getPariticipantData();
    }
  }, [selectedCourse, getPariticipantData]);

  const uploadParticipantData = async (participantDataItems) => {
    try {
      if (participantDataItems.length > 0) {
        const transformedData = participantDataItems.map((item) => ({
          first_name: item.user_name,
          last_name: "",
          email: item.user_email,
          batch: item.course_name + "-" +  selectedCourse,
          phone: "N/A",
          course: item.course_name,
        }));
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/learner-upload/`,
          {
            participent: transformedData,
          },
          {
            headers: {
              Authorization: `Token ${sessionStorage.getItem("token")}`,
            },
          }
        );

        if (response.status === 200) {
          console.log("Participants uploaded successfully!");
        }
      }
    } catch (error) {
      console.error("Error uploading participants:", error);
    }
  };

  return (
    <main className="xl:col-start-2 pt-4 xl:col-end-7 w-[98%] mx-auto">
      <div className="flex justify-end gap-4">
        <BatchSampleExcel
          data={DUMMY_LEARNERS}
          disabled={false}
          title="Sample"
        />
        <Button
          type="primary"
          className="block px-6"
          onClick={() => setOpenTable(true)}
        >
          Add Batch
        </Button>
        <Button className="block px-6" onClick={importFromThinkific}>
          Import from Thinkific
        </Button>
      </div>

      <Modal
        title="Import from Thinkific"
        visible={openModal}
        onCancel={() => setOpenModal(false)}
        footer={[
          <Button key="cancel" onClick={() => setOpenModal(false)}>
            Cancel
          </Button>,
          <Button
            key="import"
            type="primary"
            onClick={() => {
              uploadParticipantData(participantData.items);
              setOpenModal(false);
            }}
          >
            Import
          </Button>,
        ]}
      >
        {loading ? (
          <div>
            <Spin
              spinning={loading}
              tip="Loading"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "#652E83",
              }}
            ></Spin>
          </div>
        ) : (
          <div>
            <Select
              placeholder="Select a course from Thinkific"
              style={{ width: "100%" }}
              value={selectedCourse}
              onChange={(value) => setSelectedCourse(value)}
              showSearch={true}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {Array.isArray(apiResponse.items) &&
              apiResponse.items.length > 0 ? (
                apiResponse.items.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))
              ) : (
                <Select.Option disabled value="no-courses">
                  No courses available
                </Select.Option>
              )}
            </Select>
            {selectedCourse && (
              <>
                {participantDataLoading && <Spin />}
                {participantData && !participantDataLoading ? (
                  <>
                    <p className="mt-4">
                      Total Participants: {participantData?.items?.length}
                    </p>
                    <div className="mt-2">
                      {Array.isArray(participantData.items) &&
                      participantData.items.length > 0 ? (
                        <Table
                          dataSource={participantData.items}
                          columns={[
                            {
                              title: "User Name",
                              dataIndex: "user_name",
                              key: "user_name",
                            },
                            {
                              title: "Email",
                              dataIndex: "user_email",
                              key: "user_email",
                            },
                          ]}
                        />
                      ) : (
                        <p>No participants found for this course.</p>
                      )}
                    </div>
                  </>
                ) : (
                  participantDataError &&
                  !participantDataLoading && (
                    <p>Failed to load participant data.</p>
                  )
                )}
              </>
            )}
          </div>
        )}
      </Modal>

      {openTable && <ReadFile setOpenTable={setOpenTable} />}
      <Table
        className="my-4 border-2 max-h-table"
        dataSource={batchesListWithKeys}
        columns={columns}
        expandable={{
          expandedRowRender,
        }}
        onExpand={(expanded, record) =>
          expanded === true
            ? setExpandedRow(record.batch)
            : setExpandedRow(undefined)
        }
        expandedRowKeys={expandedRow ? [expandedRow] : []}
      />
    </main>
  );
};

export { BatchView };
