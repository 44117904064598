import { Alert, AsideBar, Header, RequireAuth } from "components";
import {
  BatchView,
  ConfirmedSessions,
  EditCoachConfirmedSlots,
  ForgotPassword,
  Help,
  Login,
  // Email,
  // CoachingSessionSlotBooking,
  // ManageCourses,
  // ManageLearners,
  // ViewCoachSlots,
  // ManageFaculties,
  // ViewLearnerSlots,
  // ForgotPassword,
  // EditCoachSlots,
  // EditLearnerSlots,
  ManageCoaches,
  ManageEvents,
  RequestSessions,
	UpcomingSessions
} from "pages";
// import { Email } from "../src/pages/email/Email";
// import { Email } from "pages/email/Email";
// import { Preview } from "pages/email/email";
// import { MdPreview } from "react-icons/md";
import { Routes, Route, useLocation } from "react-router-dom";
import { EmailSupport } from "pages/email-support/EmailSupport";
import { PreviewPage } from "pages/preview/Preview";
import { FinalPreview } from "pages/succes-preview/SuccesPreview";
import { Templates } from "pages/templates1/Templates";
import { Templates1 } from "pages/templates/Templates1";
import { SuccessPage } from "pages/success-page/SuccessPage";
import { SuccessTestPage } from "pages/success-page/SuccessTestPage";
import EmailData from "pages/email-data/EmailData";

const App = () => {
  const { pathname } = useLocation();

  return (
    <>
      <Header />
      <Alert />
      {pathname !== "/" ? (
        <div className="xl:grid xl:grid-cols-6">
          <AsideBar />
          <Routes>
            <Route element={<RequireAuth />}>
              {/* <Route
              path="/coaching-session-slot-booking"
              element={<CoachingSessionSlotBooking />}
            /> */}
              {/* <Route path="/manage-courses" element={<ManageCourses />} />
            <Route path="/manage-learners" element={<ManageLearners />} /> */}
              <Route path="/coach-profiles" element={<ManageCoaches />} />
              <Route
                path="/participant-manage-events"
                element={<ManageEvents />}
              />

              {/* <Route path="/manage-faculties" element={<ManageFaculties />} />
            <Route path="/view-coach-slots" element={<ViewCoachSlots />} />
            <Route path="/edit-coach-slots" element={<EditCoachSlots />} />
            <Route path="/view-learner-slots" element={<ViewLearnerSlots />} />
            <Route path="/edit-learner-slots" element={<EditLearnerSlots />} /> */}
              <Route
                path="/coach-request-availability"
                element={<RequestSessions />}
              />
              <Route path="/participant-batch-view" element={<BatchView />} />
              <Route
                path="/confirmed-sessions"
                element={<ConfirmedSessions />}
              />
              <Route
                path="/coach-edit-availability"
                element={<EditCoachConfirmedSlots />}
              />
              <Route path="/help" element={<Help />} />
            </Route>
            <Route path="/email" element={<EmailSupport />}></Route>
            <Route path="/templates" element={<Templates1 />}></Route>

            <Route path="/preview" element={<PreviewPage />}></Route>
            <Route path="/templates1" element={<Templates />}></Route>
            <Route path="/successpreview" element={<FinalPreview />}></Route>
            <Route path="/successpage" element={<SuccessPage />}></Route>
            <Route path="/successtestpage" element={<SuccessTestPage/>}></Route>
            <Route path="/emailsdata" element={<EmailData/>}></Route>
						<Route path="/upcoming-sessions" element={<UpcomingSessions / >} /> 
          </Routes>
        </div>
      ) : (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Routes>
      )}
    </>
  );
};

export default App;
