import React from "react";
import { RiCloseCircleFill } from "assets/icons/icons";
import { newDaysArray } from "utils";

const ConfirmedSlots = ({
  formattedData,
  date,
  setDeleteSlotDetails,
  bookedSessionIds,
}) => {
  // const [modal, setModal] = useState(null);
  // const [editSlot, setEditSlot] = useState(null);

  let data = formattedData[date];
  data = data.sort((a, b) => Number(a.start_time) - Number(b.start_time));
  date = date.split("-");
  date = `${date[2]}-${date[1]}-${date[0]}`;
  let children = data?.map((slot) => {
    return (
      <div
        className={`px-6 py-3 rounded relative ${
          bookedSessionIds.includes(slot.id) ? "bg-success" : "bg-sky-500"
        }`}
      >
        <p>
          {new Date(+slot.start_time).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
          })}{" "}
          -{" "}
          {new Date(+slot.end_time).toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </p>
        {!bookedSessionIds.includes(slot.id) && (
          <>
            <RiCloseCircleFill
              className="absolute cursor-pointer text-lg -top-1 -right-1"
              onClick={() => {
                setDeleteSlotDetails(slot);
              }}
            />
            {/* <MdEdit
            className="absolute cursor-pointer text-lg -bottom-1 -right-1"
            onClick={() => {
              setModal(true);
              setEditSlot(slot);
            }}
          /> */}
          </>
        )}
      </div>
    );
  });

  const dateArray = date.split("-");
  const formattedDate = new Date(
    `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`
  );

  return (
    <>
      <h4 className="font-semibold text-md relative text-slate-800 px-4 py-3 rounded bg-gray-300">
        {date} ({newDaysArray[formattedDate.getDay()]}) :
      </h4>
      <div className="flex gap-2 flex-wrap w-[95%] mt-1 mx-auto mb-4">
        {children}
      </div>
      {/* {modal && (
        <section className="mt-2 mb-6 w-fit mx-auto px-4 py-2 border-slate-500 border">
          <p className="text-lg mb-2">Update coach confirmed slot:</p>
          <EditModal
            slot={editSlot}
            setModal={setModal}
            formattedData={formattedData}
          />
        </section>
      )} */}
    </>
  );
};

export { ConfirmedSlots };
