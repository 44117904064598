const daysArray = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const newDaysArray = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const DUMMY_LEARNERS = [
  {
    first_name: "John",
    last_name: "Doe",
    email: "johndoe@example.com",
    batch: "IWA0001",
    phone: "1234567890",
    course: "Influence without authority",
    start_date: "01-01-2023",
    end_date: "28-01-2023",
  },
  {
    first_name: "Jane",
    last_name: "Smith",
    email: "janesmith@example.com",
    batch: "IWA0001",
    phone: "9876543210",
    course: "Influence without authority",
    start_date: "01-01-2023",
    end_date: "28-01-2023",
  },
  {
    first_name: "Alex",
    last_name: "Johnson",
    email: "alexjohnson@example.com",
    batch: "IWA0001",
    phone: "5555555555",
    course: "Influence without authority",
    start_date: "01-01-2023",
    end_date: "28-01-2023",
  },
  {
    first_name: "Emily",
    last_name: "Wilson",
    email: "emilywilson@example.com",
    batch: "IWA0001",
    phone: "9999999999",
    course: "Influence without authority",
    start_date: "01-01-2023",
    end_date: "28-01-2023",
  },
];

export { daysArray, newDaysArray, DUMMY_LEARNERS };
