import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { setStatusArrayForTimePicker } from "redux-management";
import { Select } from "antd";
import React, { useState } from "react";
import * as moment from "moment";
import { format } from "date-fns";
import { useEffect } from "react";
import { useRef } from "react";
const { Option } = Select;

const SlotWrapper = ({
  slot,
  dispatchSlotData,
  day,
  allSlotsForTheDate,
  index,
  allTimeSlots,
}) => {
  let { id, startTime, endTime } = slot;
  const dispatch = useDispatch();
  const [endTimeStatus, setEndTimeStatus] = useState("");
  const [endTimeError, setEndTimeError] = useState("");
  const newStartEntry = useRef("");
  const { statusArrayForTimePicker } = useSelector(
    (state) => state.defineCoachingSessions
  );
  let coachingSessionDate = day.split("-");
  coachingSessionDate = `${coachingSessionDate[2]}-${coachingSessionDate[1]}-${coachingSessionDate[0]}`;

  let obj = [...allSlotsForTheDate];
  let sortedSlotObjects = obj?.sort(
    (a, b) => a.startTime?._d?.getTime() - b.startTime?._d?.getTime()
  );

  newStartEntry.current = new Date(
    Number(
      sortedSlotObjects[sortedSlotObjects?.length - 2]?.endTime?._d?.getTime()
    ) +
      30 * 60000
  ).toLocaleTimeString([], {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  });
  useEffect(() => {
    if (startTime === "" && endTime === "" && index === 0) {
      dispatchSlotData({
        type: "SET_TIME",
        payload: {
          id,
          time: moment(`${coachingSessionDate}T09:00:00`),
          day,
          type: "START_TIME",
        },
      });
      dispatchSlotData({
        type: "SET_TIME",
        payload: {
          id,
          time: moment(`${coachingSessionDate}T21:00:00`),
          day,
          type: "END_TIME",
        },
      });
    }
    if (endTime === "" && index !== 0) {
      dispatchSlotData({
        type: "SET_TIME",
        payload: {
          id,
          time: moment(`${coachingSessionDate}T${newStartEntry.current}:00`),
          day,
          type: "START_TIME",
        },
      });

      dispatchSlotData({
        type: "SET_TIME",
        payload: {
          id,
          time: moment(`${coachingSessionDate}T21:00:00`),
          day,
          type: "END_TIME",
        },
      });
    }
  }, [
    coachingSessionDate,
    id,
    endTime,
    startTime,
    day,
    dispatchSlotData,
    index,
  ]);

  const children = allTimeSlots.map((time) => {
    let day = "AM";
    let displayTime = "";
    if (time > "11:30") {
      day = "PM";
    }
    if (time > "12:30") {
      displayTime = time.split(":");
      displayTime[0] = `0${Number(displayTime[0]) - 12}`;
      displayTime = displayTime.join(":");
    } else {
      displayTime = time;
    }
    return <Option key={time} value={time}>{`${displayTime} ${day}`}</Option>;
  });

  return (
    <>
      <div className="flex items-center w-fit gap-2 md:gap-6">
        <Select
          showSearch
          style={{
            width: 130,
          }}
          placeholder="Search to Select"
          optionFilterProp="children"
          onSelect={(value) => {
            const time = moment(
              `${format(new Date(coachingSessionDate), "yyyy-MM-dd")} ${value}`,
              "YYYY-MM-DD HH:mm"
            );
            dispatchSlotData({
              type: "SET_TIME",
              payload: { id, time, day, type: "START_TIME" },
            });
          }}
          filterOption={(input, option) =>
            option.children
              .toLowerCase()
              .split(" ")
              .join("")
              .includes(input.toLowerCase().split(" ").join(""))
          }
          defaultValue={{
            value: index === 0 ? "09:00" : newStartEntry.current,
          }}
        >
          {children}
        </Select>{" "}
        -{" "}
        <Select
          showSearch
          style={{
            width: 130,
          }}
          status={endTimeStatus}
          placeholder="Search to Select"
          optionFilterProp="children"
          onSelect={(value) => {
            const time = moment(
              `${format(new Date(coachingSessionDate), "yyyy-MM-dd")} ${value}`,
              "YYYY-MM-DD HH:mm"
            );
            if (
              startTime?._d?.toLocaleTimeString([], {
                hour12: false,
                hour: "2-digit",
                minute: "2-digit",
              }) <
              time?._d?.toLocaleTimeString([], {
                hour12: false,
                hour: "2-digit",
                minute: "2-digit",
              })
            ) {
              endTimeStatus !== "" && setEndTimeStatus("");
              setEndTimeError("");
              statusArrayForTimePicker > 0 &&
                dispatch(setStatusArrayForTimePicker("decrement"));
              return dispatchSlotData({
                type: "SET_TIME",
                payload: { id, time, day, type: "END_TIME" },
              });
            }
            setEndTimeStatus("error");
            dispatch(setStatusArrayForTimePicker("increment"));
            setEndTimeError("Ending time should not be before starting time");
          }}
          filterOption={(input, option) =>
            option.children
              .toLowerCase()
              .split(" ")
              .join("")
              .includes(input.toLowerCase().split(" ").join(""))
          }
          defaultValue={{
            value: "21:00",
          }}
        >
          {children}
        </Select>
        <button
          onClick={() =>
            dispatchSlotData({
              type: "DELETE_SLOT",
              payload: { day, id, perDayLength: allSlotsForTheDate.length },
            })
          }
          className="text-xl ml-2 text-gray-600"
        >
          <RiDeleteBin6Line />
        </button>
      </div>
      <p className="text-red-500">{endTimeError}</p>{" "}
    </>
  );
};

export { SlotWrapper };
