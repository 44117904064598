import React, { useReducer, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCoaches } from "redux-management";
import { FaEdit } from "assets/icons/icons";
import { AddNewCoach } from "./add-new-coach/AddNewCoach";
import { Button, Modal, Table, Tag } from "antd";
import { format } from "date-fns";
import { Loader } from "components";
import { useGetApi } from "hooks/useGetApi";
import { utils, write } from "xlsx";
import { writeFile } from "xlsx";
import moment from "moment";

const ManageCoaches = () => {
  const manageCoachesReducer = (state, { type, payload }) => {
    switch (type) {
      case "SET_IS_ADD_COACH":
        return { ...state, isAddCoach: payload };
      case "SET_IS_EDIT":
        return { ...state, isEdit: payload };
      case "ON_CLICK_EDIT_ICON":
        return {
          ...state,
          isAddCoach: true,
          isEdit: true,
          editDetails: payload,
        };
      case "HANDLE_CLOSE_MODAL":
        return {
          ...state,
          isAddCoach: false,
          isEdit: false,
        };
      default:
        return state;
    }
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };

  const handleOk = () => {
    setIsModalOpen(false);
    downloadExcelFile();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [manageCoachesState, manageCoachesDispatch] = useReducer(
    manageCoachesReducer,
    {
      isAddCoach: false,
      isEdit: false,
      editDetails: null,
    }
  );
  const { isAddCoach, isEdit, editDetails } = manageCoachesState;
  const dispatch = useDispatch();
  const { manageCoachesStatus, allCoachesList, addCoachStatus } = useSelector(
    (state) => state.manageCoaches
  );

  const { data, isLoading } = useGetApi(
    `${process.env.REACT_APP_BASE_URL}/confirmed-slots-of-coach/`
  );

  useEffect(() => {
    dispatch(getCoaches());
  }, [dispatch]);

  const columns = [
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      fixed: "left",
      width: 180,
      render: (_, coach) => {
        return (
          <p>{`${coach.first_name} ${coach.middle_name} ${coach.last_name}`}</p>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 250,
    },
    {
      title: "Mobile",
      dataIndex: "phone",
      key: "phone",
      width: 150,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      width: 120,
    },
    {
      title: "Fee",
      dataIndex: "fee",
      key: "fee",
      width: 120,
    },
    {
      title: "Meet Link",
      dataIndex: "meet_link",
      key: "meet_link",
      width: 300,
    },
    {
      title: "Active Since",
      dataIndex: "activeSince",
      key: "activeSince",
      width: 150,
      render: (activeSince) => (
        <p>{format(new Date(activeSince), "dd-MM-yyyy")}</p>
      ),
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      width: 120,
      render: (isActive) => {
        let color = isActive ? "green" : "red";
        return (
          <Tag color={color} key={isActive}>
            {isActive ? "Active" : "Inactive"}
          </Tag>
        );
      },
    },
    {
      title: "Edit",
      dataIndex: "edit",
      key: "edit",
      fixed: "right",
      width: 70,
      render: (_, coach) => {
        return (
          <FaEdit
            className="text-2xl cursor-pointer"
            onClick={() => {
              manageCoachesDispatch({
                type: "ON_CLICK_EDIT_ICON",
                payload: coach,
              });
            }}
          />
        );
      },
    },
  ];

  const downloadExcelFile = () => {
    // Create a new workbook
    const wb = utils.book_new();

    // Loop through the coach data and create sheets for each coach
    data.forEach((coachData) => {
      const { coach_name, slots } = coachData;
      const wsData = [];

      // Create the header row
      wsData.push(["Coach Name", "Date", "Time"]);

      // Add slot data for each coach
      slots.forEach((slot) => {
        const { SESSION_DATE, start_time, end_time } = slot;
        wsData.push([
          coach_name,
          SESSION_DATE,
          `${moment(new Date(+start_time)).format("hh:mm A ")} - ${moment(
            new Date(+end_time)
          ).format("hh:mm A ")}`,
        ]);
      });

      // Create a worksheet for each coach
      const ws = utils.aoa_to_sheet(wsData);
      utils.book_append_sheet(wb, ws, coach_name);
    });

    // Save the workbook as an Excel file
    writeFile(wb, "coaches_availability.xlsx");
  };

  const reverseAllCoachesList = [...allCoachesList].reverse();

  if (addCoachStatus === "pending") {
    return (
      <main className="xl:col-start-2 pt-4 xl:col-end-7 w-[98%] mx-auto">
        <Loader />
      </main>
    );
  }

  return (
    <main className="xl:col-start-2 pt-4 xl:col-end-7 w-[98%] mx-auto">
      {manageCoachesStatus === "pending" ? (
        <Loader />
      ) : manageCoachesStatus === "rejected" ? (
        <h2 className="text-xl text-center">
          There is a glitch, please reload the page.
        </h2>
      ) : (
        <>
          <div className="flex justify-end gap-2">
            <Button onClick={showModal} type="primary">
              Coach availibilty
            </Button>
            <Button
              type="primary"
              className="py-1 px-2"
              onClick={() =>
                manageCoachesDispatch({
                  type: "SET_IS_ADD_COACH",
                  payload: true,
                })
              }
            >
              Add new coach
            </Button>
          </div>
          <h1 className="mt-3 font-medium text-3xl text-center">All Coaches</h1>
          <Table
            className="my-4 border-2"
            dataSource={reverseAllCoachesList}
            columns={columns}
            pagination={{
              pageSize: 30,
            }}
            scroll={{
              x: 1660,
              y: 370,
            }}
          />
          <Modal
            footer={false}
            title="Coach Availibilty"
            visible={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <div className="flex flex-col">
              <div>
                There are {data?.length} coaches who have given their
                availabilities. Do you want to download the report for all the
                coaches?
              </div>
              <div className="mt-[1rem] ml-auto">
                <Button className="mr-[.5rem]" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button type="primary" onClick={handleOk}>
                  Download
                </Button>
              </div>
            </div>
          </Modal>

          <AddNewCoach
            isAddCoach={isAddCoach}
            isEdit={isEdit}
            editDetails={editDetails}
            manageCoachesDispatch={manageCoachesDispatch}
          />
        </>
      )}
    </main>
  );
};

export { ManageCoaches };
