import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "redux-management";

const Alert = () => {
  const { alert } = useSelector((state) => state.alert);
  const { status, message, isAlert } = alert;
  const colors = {
    success: {
      text: "text-green-700",
      backgroundForBefore: "bg-green-700",
      backgroundAlert: "bg-green-100",
      border: "border border-green-700",
    },
    failure: {
      text: "text-red-700",
      backgroundForBefore: "bg-red-700",
      backgroundAlert: "bg-red-100",
      border: "border border-red-700",
    },
  };
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      isAlert &&
        dispatch(
          setAlert({
            message: "",
            isAlert: false,
            status: "",
          })
        );
    }, 3500);
  }, [dispatch, isAlert]);

  if (!isAlert) {
    return null;
  }

  return (
    <section
      className={`${colors[status].text} ${colors[status].backgroundAlert} ${colors[status].border} flex gap-2 rounded z-alert fixed top-11 left-2/4 -translate-x-2/4 w-10/12 sm:w-96`}
    >
      <div
        className={`w-2 shrink-0 ${colors[status].backgroundForBefore}`}
      ></div>
      <div className="my-2 mr-6">{message}</div>
    </section>
  );
};

export { Alert };
