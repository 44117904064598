import React, { useState, } from "react";
import imageG from '../../assets/images/img.gif';
import image from '../../assets/images/meeraq_logo_color.png';
import imageL from '../../assets/images/linkdin.png';
import imageI from '../../assets/images/instagram.png';
// import imageF from '../assets/images/facebook.png';
import imageM from '../../assets/images/meeraq.png';
import imageF from '../../assets/images/facebook.png';




const template = `<table style="border-collapse: collapse;">
<tbody>
  <tr style="height: 150.25pt;">
    <td valign="top" style="width:609.75pt;height:150.25pt;padding:0.75pt;border:1.5pt solid #516EB4">
      <p style="display: block;aspect-ratio:3.80/1;text-align: center;font-size:50pt;font-family:Brush Script MT,sans-serif;background-color:lightgray;margin:0;line-height:105%">
        <span style="color:black;size:12pt;line-height:105%;padding:1pt">
          <br>
          <img  src=${image} style=" width:200pt;height:50pt"/>
          <br> 
        </span>
        <span style="color:black;size:12pt;line-height:105%">
          </span>
      </p>
    </td>    
  </tr>
  <tr style="height: 322.35pt;">
    <td valign="top" style="width:609.75pt;height:322.35pt;padding:10.8pt 18pt;border:1.5pt solid #516EB4;margin-bottom:20px">
      <p style="font-size:11pt;font-family:Calibri,sans-serif;background-color:white;margin:0;line-height:105%">
        <span>
          <b>
            <span style="color:#7030A0;font-size:15pt;line-height:105%">
              Dear 
            </span>
          </b>
        </span>
      </p> 
      <p style="font-size:11pt;font-family:Calibri,sans-serif;background-color:white;margin:0;line-height:105%">
        <span style="color:#D3075E;font-size:12pt;line-height:105%">
            Meeraq
        </span>
        <span style="color:black;size:12pt;line-height:105%">
            has requested for  
        </span>
        <span style="color:#D3075E;font-size:12pt;line-height:105%">
              session name
        </span>
        <span style="color:black;size:12pt;line-height:105%">
            with 
        </span>
        <span>
          <span style="color:#D3075E;font-size:12pt;line-height:105%">
            coach name;
          </span>
        </span>
        <span style="font-size:12pt;line-height:105%">
        </span>
      </p> 
      <br></br>
      <p style="font-size:11pt;font-family:Calibri,sans-serif;background-color:white;margin:0;line-height:105%">
        <span>
          <span style="color:black;size:12pt;line-height:105%">
            Requested Slots:
          </span>
          <span lang="en-US" style="color:#222222;font-size:12pt;line-height:105%">
            <a href="https://caas.meeraq.com/" target="_blank" rel="noopener noreferrer" data-auth="NotApplicable" title="https://caas.meeraq.com/">Slot 1 </a>
            |
            <a href="https://caas.meeraq.com/" target="_blank" rel="noopener noreferrer" data-auth="NotApplicable" title="https://caas.meeraq.com/">Slot 2 </a>
            |
            <a href="https://caas.meeraq.com/" target="_blank" rel="noopener noreferrer" data-auth="NotApplicable" title="https://caas.meeraq.com/">Slot 3</a>
          </span>
        </span>
        <span style="font-size:12pt;line-height:105%">
        </span>
      </p> 
      <p style="font-size:11pt;font-family:Calibri,sans-serif;background-color:white;margin:0;line-height:105%">
        <span>
          <span lang="en-US" style="color:#222222;font-size:12pt;line-height:105%">
          <a href="https://caas.meeraq.com/" target="_blank" rel="noopener noreferrer" data-auth="NotApplicable" title="https://caas.meeraq.com/">Click here </a>
          to add your password and login.
          </span>
        </span>
        <span style="font-size:12pt;line-height:105%">
        </span>
      </p> 
      <br></br><br></br>
      <p style="font-size:11pt;font-family:Calibri,sans-serif;margin:0;line-height:105%">
        <span style="color:#999999;font-size:10pt;padding:0;border:1pt none win dowtext;line-height:105%">
          Thanks & Regards,&nbsp;&nbsp
        </span>
      </p>
      <p style="font-size:11pt;font-family:Calibri,sans-serif;margin:0;line-height:105%">
        <span style="color:#5E327C;font-size:10pt;padding:0;border:1pt none win dowtext;line-height:105%">
          IlhamKhan Pathan
        </span>
        <span style="color:black;size:12pt;line-height:105%">
        </span>
      </p>
      <p style="font-size:11pt;font-family:Calibri,sans-serif;margin:0;line-height:105%">
        <span style="color:#666666;font-size:10pt;padding:0;border:1pt none win dowtext;line-height:105%">
          Program management
        </span>
        <span style="color:black;size:12pt;line-height:105%">
        </span>
      </p>
      <p style="font-size:11pt;font-family:Calibri,sans-serif;margin:0;line-height:105%">
        <span style="color:#999999;font-size:10pt;padding:0;border:1pt none win dowtext;line-height:105%">
          +91 9725250897
        </span>
        <span style="color:black;size:12pt;line-height:105%">
        </span>
      </p>
      <p style="font-size:11pt;font-family:Calibri,sans-serif;margin:0;line-height:105%">
        <span style="color:black;">
        <img src=${imageG} style=" width:200pt;height:25pt"/>
        </span>
        <span style="color:black;size:12pt;line-height:105%">
          </span>
      </p>
      <p style="font-size:11pt;font-family:Calibri,sans-serif;margin:0;line-height:105%">
        <span style="color:black">
          <a href="https://www.meeraq.com/" target="_blank" rel="noopener noreferrer" data-auth="NotApplicable" title="https://www.meeraq.com/" data-linkindex="0" >
            <span style="color:black;text-decoration:none;padding:0;border:1pt none windowtext;">
              <img  src=${imageM} style=" width:66pt;height:25pt"/>
            </span>
          </a>
          <a href="https://www.linkedin.com/company/meeraq-hq/" target="_blank" rel="noopener noreferrer" data-auth="NotApplicable" title="https://www.linkedin.com/company/meeraq-hq/" data-linkindex="1" >
            <span style="color:black;text-decoration:none;padding:0;border:1pt none windowtext;">
              <img  src=${imageL} style=" width:27pt;height:30pt"/>
            </span>
          </a>
          <a href="https://www.instagram.com/meeraq_hq/" target="_blank" rel="noopener noreferrer" data-auth="NotApplicable" title="https://www.instagram.com/meeraq_hq/" data-linkindex="1" >
            <span style="color:black;text-decoration:none;padding:0;border:1pt none windowtext;">
              <img  src=${imageI} style=" width:27pt;height:30pt"/>
            </span>
          </a>
          <a href="https://www.facebook.com/Meeraq.HQ" target="_blank" rel="noopener noreferrer" data-auth="NotApplicable" title="https://www.facebook.com/Meeraq.HQ/" data-linkindex="1" >
            <span style="color:black;text-decoration:none;padding:0;border:1pt none windowtext;">
              <img  src=${imageF} style=" width:27pt;height:30pt"/>
            </span>
          </a>
        </span>
      </p>
      <p style="font-size:11pt;font-family:Calibri,sans-serif;margin:0;line-height:105%">
        <span>
          <span style="font-family:Times New Roman,serif;">&nbsp;
          </span>
        </span>
      </p>
    </td>
  </tr>
</tbody>
</table>

`;

export default template;