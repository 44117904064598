import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCoaches, getEventsList } from "redux-management";
import { ConfirmedSessionsTable } from "./confirmed-sessions-table/ConfirmedSessionsTable";
import { DeleteCoachingSessionSlotModal } from "./delete-coaching-session-slot-modal/DeleteCoachingSessionSlotModal";

const ConfirmedSessions = () => {
  const [deleteSlotDetails, setDeleteSlotDetails] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEventsList());
    dispatch(getCoaches());
  }, [dispatch]);

  return (
    <main className="xl:col-start-2 pt-4 xl:col-end-7 w-[98%] mx-auto">
      <ConfirmedSessionsTable setDeleteSlotDetails={setDeleteSlotDetails} />
      {deleteSlotDetails && (
        <DeleteCoachingSessionSlotModal
          deleteSlotDetails={deleteSlotDetails}
          setDeleteSlotDetails={setDeleteSlotDetails}
        />
      )}
    </main>
  );
};

export { ConfirmedSessions };
