import {
  Button,
  Input,
  Space,
  Table,
  Modal,
  notification,
  Tooltip,
} from "antd";
import { format } from "date-fns";
import React, { useRef, useState, useEffect } from "react";
import Highlighter from "react-highlight-words";
import { FaEdit, MdDelete } from "assets/icons/icons";
import { useDispatch, useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { getBatchesList, getCoaches, setAlert } from "redux-management";
import { EventModal } from "../event-modal/EventModal";
import { AiOutlineCopy } from "react-icons/ai";
import axios from "axios";
import {
  getAvailableSlotsByEventId,
  getEventsList,
} from "redux-management/slices/manage-events/manageEventsService";
import moment from "moment";

const EventTable = ({
  eventExpired,
  setEditEventDetails,
  setCreateEvent,
  setDeleteEventDetails,
}) => {
  const dispatch = useDispatch();
  const { eventsList } = useSelector((state) => state.manageEvents);
  const [eventDetails, setEventDetails] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [totalLearners, setTotalLearners] = useState(null);

  const handleOkWithEventId = (eventId) => {
    setIsModalVisible(true);
    handleOk(eventId);
  };

  const { availableSlotsByEventId } = useSelector(
    (state) => state.manageEvents
  );

  useEffect(() => {
    if (eventDetails) {
      dispatch(
        getAvailableSlotsByEventId(
          eventsList.find((item) => item.id === eventDetails.id)._id
        )
      );
    }
  }, [dispatch, eventDetails, eventsList]);

  useEffect(() => {
    if (selectedEvent) {
      const eventId = selectedEvent._id;
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/get-learners-with-no-sessions/${eventId}`
        )
        .then((response) => {
          if (response.status === 200) {
            const total = response.data.total_learners;
            setTotalLearners(total);
            if (total === 0) {
              setDisabledEvents([...disabledEvents, eventId]);
            } else {
              setDisabledEvents(disabledEvents.filter((id) => id !== eventId));
            }
          } else {
            throw new Error("GET API request failed");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [selectedEvent]);

  const getSlots = (slots, type, event) => {
    const res = [];
    slots.forEach((slot) => {
      if (type === "AVAILABLE") {
        if (
          new Date(slot.date) >= new Date(event.start_date) &&
          new Date(slot.date) <= new Date(event.end_date)
        ) {
          res.push(slot);
        }
      } else {
        if (
          new Date(slot.slot.date) >= new Date(event.start_date) &&
          new Date(slot.slot.date) <= new Date(event.end_date)
        ) {
          res.push(slot);
        }
      }
    });
    return res;
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = (batch) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/get-learner-by-batch/${batch}`)
      .then((response) => {
        setIsModalVisible(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleOk = (eventId) => {
    setIsModalVisible(false);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/get-learners-with-no-sessions/${eventId}`
      )
      .then((response) => {
        if (response.status === 200) {
          const recipient_emails = response.data.learners_with_no_sessions;
          return axios.post(
            `${process.env.REACT_APP_BASE_URL}/send-emails-to-learners/`,
            {
              recipient_emails: recipient_emails,
              event_id: eventId,
              scheduled_for: new Date(),
            }
          );
        } else {
          throw new Error("GET API request failed");
        }
      })
      .then((postResponse) => {
        if (postResponse.status === 200) {
          dispatch(getEventsList());
          dispatch(getCoaches());
          dispatch(getBatchesList());
          notification.success({
            message: "Emails Sent",
            description: "Emails have been sent successfully.",
          });
        } else {
          throw new Error("POST API request failed");
        }
      })
      .catch((error) => {
        console.error(error);
        notification.error({
          message: "Error",
          description: "Failed to send emails.",
        });
      });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const [disabledEvents, setDisabledEvents] = useState([]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 180,
      ...getColumnSearchProps("name"),
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      width: 150,
      sorter: (a, b) => {
        if (a.start_date > b.start_date) {
          return 1;
        }
        if (a.start_date < b.start_date) {
          return -1;
        }
        return 0;
      },
      render: (startDate) => <p>{format(new Date(startDate), "dd-MM-yyyy")}</p>,
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      width: 150,
      render: (endDate) => <p>{format(new Date(endDate), "dd-MM-yyyy")}</p>,
    },
    {
      title: "Expiry Date",
      dataIndex: "expire_date",
      key: "expire_date",
      width: 150,
      sorter: (a, b) => {
        if (a.expire_date > b.expire_date) {
          return 1;
        }
        if (a.expire_date < b.expire_date) {
          return -1;
        }
        return 0;
      },
      render: (expiryDate) => (
        <p>{format(new Date(expiryDate), "dd-MM-yyyy")}</p>
      ),
    },
    {
      title: "Event Link",
      dataIndex: "link",
      key: "link",
      width: 300,
      render: (eventLink, event) => {
        return (
          <p
            className={`flex gap-1 items-center ${
              event.expire_date >= format(new Date(), "yyyy-MM-dd")
                ? "cursor-pointer"
                : ""
            }`}
            onClick={() => {
              if (event.expire_date >= format(new Date(), "yyyy-MM-dd")) {
                navigator.clipboard.writeText(eventLink);
                dispatch(
                  setAlert({
                    message: "Copied to clipboard",
                    isAlert: true,
                    status: "success",
                  })
                );
              }
            }}
          >
            <AiOutlineCopy className="text-lg" /> {eventLink.slice(0, 26)}...
          </p>
        );
      },
    },
    {
      title: "Send Event Link",
      dataIndex: "modal",
      key: "modal",
      width: 180,
      hidden: eventExpired,
      render: (_, event) => {
        const isDisabled = disabledEvents.includes(event._id);
        return (
          <div>
            <Tooltip
              title={isDisabled ? "All learners have booked the session" : ""}
            >
              <Button
                type="primary"
                className="rounded cursor-pointer"
                onClick={() => {
                  setSelectedEvent(event);
                  if (!isDisabled) {
                    showModal(event.batch);
                  }
                }}
                disabled={isDisabled}
              >
                Send Link
              </Button>
            </Tooltip>
          </div>
        );
      },
    },

    {
      title: "More details",
      dataIndex: "modal",
      key: "modal",
      width: 180,
      render: (_, event) => {
        return (
          <Button
            type="primary"
            className="rounded cursor-pointer"
            onClick={() => {
              setEventDetails(event);
            }}
          >
            Click to see more
          </Button>
        );
      },
    },
    {
      title: "Edit",
      dataIndex: "edit",
      key: "edit",
      fixed: "right",
      width: 70,
      render: (_, event) => {
        return (
          <FaEdit
            className="text-2xl cursor-pointer"
            onClick={() => {
              setEditEventDetails(event);
              setCreateEvent(true);
            }}
          />
        );
      },
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      width: 80,
      render: (_, event) => {
        return (
          <MdDelete
            className="text-xl cursor-pointer"
            onClick={() => {
              setDeleteEventDetails({
                id: event.id,
                name: event.name,
              });
            }}
          />
        );
      },
    },
  ].filter((item) => !item.hidden);

  const isDisabled = selectedEvent
    ? disabledEvents.includes(selectedEvent._id)
    : false;

  const modalContent = isDisabled ? (
    <>
      <p>All the learners have booked the session.</p>
      <p>No learners remaining.</p>
    </>
  ) : (
    <>
      {selectedEvent?.sent_to_participants?.length > 0 && (
        <>
          <p>
            You have sent the link{" "}
            <b>{selectedEvent?.sent_to_participants?.length} </b>
            times already!
          </p>
          <p className="mb-4">
            Last sent:{" "}
            {moment(
              new Date(
                +selectedEvent?.sent_to_participants[
                  selectedEvent?.sent_to_participants?.length - 1
                ]?.date
              )
            ).format("DD-MM-YYYY")}
          </p>
        </>
      )}
      <p className="mb-4">
        Are you sure you want to send the booking link to all{" "}
        <b>{totalLearners}</b> participants who didn’t book their session?
      </p>
      <p>
        <b>Total available slots :</b>{" "}
        {selectedEvent &&
          getSlots(availableSlotsByEventId, "AVAILABLE", selectedEvent).length}
      </p>
    </>
  );

  const modal = (
    <Modal
      title="Send Event Link"
      visible={isModalVisible}
      onOk={() => {
        handleOkWithEventId(selectedEvent._id);
        setIsModalVisible(false);
      }}
      onCancel={() => setIsModalVisible(false)}
      footer={isDisabled ? null : undefined}
    >
      <div style={{ fontSize: "16px" }}>{modalContent}</div>
    </Modal>
  );

  let events = eventsList
    .map((event) => ({ ...event, key: event.id }))
    .reverse();

  const filteredEvents = events.filter((event) => {
    if (
      eventExpired === true &&
      event.end_date < format(new Date(), "yyyy-MM-dd")
    ) {
      return true;
    }
    if (
      eventExpired === false &&
      event.end_date >= format(new Date(), "yyyy-MM-dd")
    ) {
      return true;
    }
    return false;
  });

  return (
    <>
      <h2 className="text-2xl">Events details:</h2>
      <Table
        className="my-4 border-2"
        dataSource={filteredEvents}
        columns={columns}
        pagination={{
          pageSize: 30,
        }}
        rowClassName={(rowData) => {
          if (rowData.expire_date < format(new Date(), "yyyy-MM-dd")) {
            return "event-link-expired bg-gray-100 text-gray-400 cursor-not-allowed";
          } else {
            return "";
          }
        }}
        scroll={{
          x: 1260,
          y: 280,
        }}
      />
      {modal}
      {eventDetails && (
        <EventModal
          rowData={eventDetails}
          setEventDetails={setEventDetails}
          eventsList={eventsList}
        />
      )}
    </>
  );
};

export { EventTable };
